import * as uuid from 'uuid';
import { injectDetailSlice, injectTableSlice, toReducer } from '../sliceFactory';

const createSubjectsReducer = () => {
  const sliceBase = {
    name: 'subjects',
    initialState: {
      subjectOffices: null,
      subjectOfficesLoading: false,
      subjectOfficesToken: uuid.v4(),
      subjectVehicles: null,
      subjectVehiclesLoading: false,
      subjectVehiclesToken: uuid.v4()
    },
    reducers: {
      fetchOffices: state => {
        state.subjectOfficesLoading = true;
      },
      fetchOfficesFinished: (state, action) => {
        state.subjectOfficesLoading = false;
        state.subjectOffices = action.payload.data;
      },
      fetchVehicles: state => {
        state.subjectVehiclesLoading = true;
      },
      fetchVehiclesFinished: (state, action) => {
        state.subjectVehiclesLoading = false;
        state.subjectVehicles = action.payload.data;
      }
    }
  };
  const withTable = injectTableSlice({ sliceBase });
  const withDetail = injectDetailSlice({ sliceBase: withTable });
  withDetail.reducers.submitDetail = state => {
    state.detailLoading = true;
  };
  withDetail.reducers.finishSubmit = state => {
    state.detailLoading = false;
  };
  withDetail.reducers.clearOffices = state => {
    state.subjectOffices = null;
    state.subjectOfficesToken = uuid.v4();
  };
  withDetail.reducers.clearVehicles = state => {
    state.subjectVehicles = null;
    state.subjectVehiclesToken = uuid.v4();
  };
  return toReducer(withDetail);
};

export default createSubjectsReducer;
