import { all, fork, takeLatest, takeLeading } from 'redux-saga/effects';
import { fetchDetail, sendData } from '../helpers/sagaHelpers';

function* watchFetchOfficeDetail() {
  yield takeLatest('offices/fetchDetail', fetchDetail);
}

function* watchSubmitOfficeDetail() {
  yield takeLeading('offices/submitOffice', sendData);
}

export default function* rootSaga() {
  yield all([fork(watchFetchOfficeDetail), fork(watchSubmitOfficeDetail)]);
}
