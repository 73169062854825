import { all, fork, takeLatest } from 'redux-saga/effects';
import { fetchGlobalTable, fetchTable, sendData } from '../helpers/sagaHelpers';

function* watchFetchGlobalTable() {
  yield takeLatest('userRoles/fetchGlobalTable', fetchGlobalTable);
}

function* watchFetchRoles() {
  yield takeLatest('userRoles/fetchTable', fetchTable);
}

function* watchSubmitRole() {
  yield takeLatest('userRoles/submitRole', sendData);
}

export default function* rootSaga() {
  yield all([fork(watchFetchGlobalTable), fork(watchFetchRoles), fork(watchSubmitRole)]);
}
