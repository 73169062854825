import AppLocale from './lang';
import { createIntl } from 'react-intl';

const locale = 'sk';

const rawTranslate = (key, values) => {
  const currentAppLocale = AppLocale[locale];
  const intl = createIntl({ locale: currentAppLocale.locale, messages: currentAppLocale.messages });
  return intl.formatMessage({ id: key }, values);
};

const LoadingState = {
  IDLE: '',
  LOADING: 'loading',
  ERROR: 'error'
};

const buildOptions = (dataList, labelField = 'name', valueField = 'id') => {
  if (dataList) {
    return dataList.map(item => ({ label: item[labelField], value: item[valueField] }));
  }
  return [];
};

const buildTranslationsMap = (valuesList, keyPrefix, messages) => {
  const rv = {};
  valuesList.forEach(v => {
    const key = `${keyPrefix}.${v}`;
    rv[v] = messages[key];
  });
  return rv;
};

const buildOptionsFromSimpleList = (valuesList, keyPrefix, messages) => {
  const rv = [];
  valuesList.forEach(itemValue => {
    rv.push({ label: messages[`${keyPrefix}.${itemValue}`], value: itemValue });
  });
  return rv;
};

export {
  rawTranslate,
  LoadingState,
  buildOptions,
  buildTranslationsMap,
  buildOptionsFromSimpleList
};
