import { all, fork, takeLatest } from 'redux-saga/effects';
import { fetchDetail, fetchTable, sendData } from '../helpers/sagaHelpers';

function* watchFetchUsersTable() {
  yield takeLatest('userManagement/fetchTable', fetchTable);
}

function* watchFetchUserDetail() {
  yield takeLatest('userManagement/fetchDetail', fetchDetail);
}

function* watchSubmitUserDetail() {
  yield takeLatest('userManagement/submitDetail', sendData);
}

export default function* rootSaga() {
  yield all([fork(watchFetchUsersTable), fork(watchFetchUserDetail), fork(watchSubmitUserDetail)]);
}
