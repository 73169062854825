import * as uuid from 'uuid';
import { createEmptySliceBase, injectDetailSlice, toReducer } from '../sliceFactory';

const createCarPriceListsReducer = () => {
  const sliceBase = createEmptySliceBase({ name: 'vehiclePriceLists' });
  const withDetail = injectDetailSlice({ sliceBase });
  const withSubList = {
    name: sliceBase.name,
    initialState: {
      ...withDetail.initialState,
      loading: false,
      data: null,
      token: uuid.v4(),
      destinations: null,
      destinationsLoading: false,
      sort: null,
      page: 1,
      limit: 10000,
      filter: {}
    },
    reducers: {
      ...withDetail.reducers,
      fetchTable: state => {
        state.loading = true;
      },
      setTableData: (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      },
      refreshTable: state => {
        state.token = uuid.v4();
      },
      setTableLoading: (state, action) => {
        state.loading = action.payload;
      },
      setDestinations: (state, action) => {
        state.destinations = action.payload.data;
      },
      fetchDestinations: state => {
        state.loading = true;
      },
      clearVehiclePriceLists: state => {
        state.data = null;
        // state.token = uuid.v4();
      }
    }
  };
  return toReducer(withSubList);
};

export default createCarPriceListsReducer;
