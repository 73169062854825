import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import sagaHelpers from './helpers/sagaHelpers';
import requestSagas from './requests/saga';
import autonomousRequestSagas from './autonomousRequests/saga';
import finishedRequestsSagas from './finishedRequests/saga';
import subjectSagas from './subjects/saga';
import officesSaga from './offices/saga';
import vehiclesSaga from './vehicles/saga';
import officePriceListSaga from './officePriceLists/saga';
import vehiclePriceListsSaga from './carPriceLists/saga';
import userManagementSaga from './userManagement/saga';
import userRolesSaga from './userRoles/saga';
import postalCodesSaga from './postalCodes/saga';
import regionsSaga from './regions/saga';
import transportSaga from './transport/saga';
import plannedTransportSaga from './plannedTransports/saga';
import documentsSaga from './documents/saga';
import finishedTransportsSaga from './finishedTransports/saga';
import storedItemsSaga from './storages/storedItemsSaga';
import storagesSaga from './storages/saga';
import listsSaga from './lists/saga';
import limitsSaga from './limits/saga';
import slnoReportsSaga from './slnoReports/saga';
import catalogNumbersSaga from './catalogNumbers/saga';
import pricingSaga from './pricing/saga';
import invoicesSaga from './invoices/saga';
import profileSaga from './profile/saga';
import officeKindsSaga from './officeKinds/saga';
import { subjectAdditionalPricingSaga } from './subjects/subjectAdditionalPricing';

export default function* rootSaga() {
  yield all([
    authSagas(),
    sagaHelpers(),
    requestSagas(),
    autonomousRequestSagas(),
    finishedRequestsSagas(),
    subjectSagas(),
    officesSaga(),
    vehiclesSaga(),
    officePriceListSaga(),
    vehiclePriceListsSaga(),
    userManagementSaga(),
    userRolesSaga(),
    postalCodesSaga(),
    regionsSaga(),
    transportSaga(),
    plannedTransportSaga(),
    documentsSaga(),
    finishedTransportsSaga(),
    storedItemsSaga(),
    storagesSaga(),
    listsSaga(),
    limitsSaga(),
    slnoReportsSaga(),
    catalogNumbersSaga(),
    pricingSaga(),
    invoicesSaga(),
    profileSaga(),
    officeKindsSaga(),
    subjectAdditionalPricingSaga()
  ]);
}
