const { FormattedMessage } = require('react-intl');
const React = require('react');
module.exports = {
  /* 01.General */
  'general.copyright': 'Gogo React © 2018 All Rights Reserved.',

  'unauthorized.title': 'Neautorizovaný prístup',
  'unauthorized.detail': 'Nemáte prístup na stránku, ktorú sa pokúšate zobraziť.',

  /* 02.User Login, Logout, Register */
  'user.login-title': 'Prihlásenie',
  'user.register': 'Registrácia',
  'user.forgot-password': 'Zabudnuté heslo',
  'user.email': 'E-mail',
  'user.password': 'Heslo',
  'user.forgot-password-question': 'Zabudli ste heslo?',
  'user.fullname': 'Plné meno',
  'user.login-button': 'Prihlásiť sa',
  'user.register-button': 'Registrovať',
  'user.reset-password-button': 'Resetovať',
  'user.buy': 'KÚPIŤ',
  'user.username': 'Username',
  'user.login-error': 'Chyba prihlasovania',
  'user.login-registration.prompt': 'Ak ešte nemáte konto v našom systéme',
  'user.login-registration.link': 'môžete sa zaregistrovať ako objednávateľ.',
  'user.forgot-password.success': 'Skontrolujte e-mailovú schránku a riaďte sa inštrukciami',
  'user.forgot-password.failure': 'Nepodarilo sa poslať žiadosť, skúste prosím znovu',
  'user.new-password': 'Nové heslo',
  'user.new-password-again': 'Znovu zadajte nové heslo',
  'user.reset-password.invalid-token': 'Chýbajúci alebo neplatný kód pre obnovu hesla',
  'user.reset-password.mismatch': 'Zadané heslá sa nezhodujú',
  'user.reset-password.success': 'Heslo úspešne zmenené. Prihláste sa',
  'user.reset-password.expired.message':
    'Vráťte sa prosím na obrazovku prihlásenia, a žiadajte o zmenu zabudnutého hesla znovu',
  'user.reset-password.expired.title': 'Žiadosť o zmenu hesla neexistuje, alebo vypršala.',
  'user.reset-password.success.button': 'Prihlásiť sa',
  'user.reset-password.success.title': 'Heslo bolo zmenené',
  'user.reset-password.success.message': 'Pokračujte prihlásením sa do systému',

  /* 03.Menu */
  'menu.home': 'Domov',
  'menu.app': 'Domov',
  'menu.dashboards': 'Dashboards',
  'menu.gogo': 'Gogo',
  'menu.start': 'Start',
  'menu.second-menu': 'Second Menu',
  'menu.second': 'Second',
  'menu.ui': 'UI',
  'menu.charts': 'Charts',
  'menu.chat': 'Chat',
  'menu.survey': 'Survey',
  'menu.todo': 'Todo',
  'menu.search': 'Search',
  'menu.docs': 'Docs',
  'menu.blank-page': 'Blank Page',

  'menu.home': 'Domov',
  'menu.requests': 'Požiadavky',
  'menu.transport': 'Objednávky',
  'menu.reports': 'Dokumenty',
  'menu.invoices': 'Faktúry',
  'menu.organizations': 'Subjekty',
  'menu.storages': 'Príjem',
  'menu.lists': 'Nastavenia',
  'menu.office-switch': 'Nastavenia',
  'menu.logout': 'Odhlásiť',

  /* 04.Error Page */
  'pages.error-title': 'Vyskytla sa chyba',
  'pages.error-code': 'Chybový kód',
  'pages.go-back-home': 'Naspäť na domovskú stránku',

  /* ORDER ITEMS (GLOBAL) */
  'items.full.fridges': 'Chladničky',
  'items.full.fridges_incomplete': 'Chladničky nekompletné',
  'items.full.wash_machines': 'Práčky',
  'items.full.small_appliances': 'Ostatné malé spotrebiče',
  'items.full.big_appliances': 'Ostatné veľké spotrebiče',
  'items.full.vacuum_cleaners': 'Vysávače',
  'items.full.mds_incomplete': 'MDS nekompletné',
  'items.full.small_ittz': 'Malé ITTZ',

  'items.short.fridges': 'Chlad.',
  'items.short.fridges_incomplete': 'Ch. nek.',
  'items.short.wash_machines': 'Práčky',
  'items.short.small_appliances': 'OMS',
  'items.short.big_appliances': 'OVS',
  'items.short.vacuum_cleaners': 'Vys.',
  'items.short.mds_incomplete': 'MDS nek.',
  'items.short.small_ittz': 'Malé ITTZ',

  /* REQUESTS */
  'requests.tabs.current.title': 'Aktuálne požiadavky',
  'requests.tabs.current.hint': 'Ešte nespracované požiadavky',
  'requests.tabs.finished.title': 'Dokončené požiadavky',
  'requests.tabs.finished.hint': 'Požiadavky, ktoré už boli dokončené',
  'requests.tabs.autonomous.title': 'Autonómne požiadavky',
  'requests.tabs.autonomous.hint': 'Autonómne požiadavky',
  'requests.tabs.button.create_request': 'Vytvoriť požiadavku',
  'requests.tabs.button.export_requests': 'Exportovať požiadavky',
  'requests.create.title': 'Vytvorenie požiadavky',
  'requests.create.button': 'Potvrdiť požiadavku',
  'requests.create.partner': 'Žiadateľ',
  'requests.create.date': 'Dátum požiadavky',
  'requests.create.type': 'Typ zvozu',
  'requests.create.origin': 'Odkiaľ',
  'requests.create.destination': 'Kam',
  'requests.create.type.default': 'Objednať zvoz',
  'requests.create.type.manual': 'Vlastný presun',

  'requests.edit_popup.actions.cancel': 'Zrušiť požiadavku',
  'requests.edit_popup.title': 'Úprava požiadavky',
  'requests.edit_popup.button': 'Upraviť požiadavku',

  'requests.filter.scheduled_at.from.placeholder': 'Preprava od',
  'requests.filter.scheduled_at.title': 'Preprava',

  /* PENDING REQUESTS */
  'requests.pending.title': 'Zoznam aktuálnych požiadaviek',
  'requests.pending.filter.query': 'Zadajte hľadaný výraz',
  'requests.pending.filter.status': 'Stav',
  'requests.pending.filter.carrier': 'Prepravca',
  'requests.pending.filter.customer': 'Odosielateľ',
  'requests.pending.filter.created_at.from': 'Od',
  'requests.pending.filter.created_at.to': 'Do',

  'requests.delete.message': 'Odstrániť vybranú požiadavku?',
  'requests.delete.error': 'Nepodarilo sa odstrániť požiadavku',

  /* FINISHED REQUESTS */
  'requests.finished.title': 'Zoznam dokončených požiadaviek',

  'requests.pending.table.status': 'Stav',
  'requests.pending.table.estimate': 'Odhad ceny',
  'requests.pending.table.ordered': 'Objednané',
  'requests.pending.table.carrier': 'Prepravca',
  'requests.pending.table.customer': 'Odosielateľ',
  'requests.pending.table.scheduled_at': 'Dátum prepravy',
  'requests.pending.table.created_at': 'Dátum vytvorenia',

  'requests.pending.table.status.no_order': 'Žiadna objednávka',

  /* AUTONOMOUS REQUESTS */
  'requests.autonomous.title': 'Autonómne požiadavky',

  'requests.invoice_popup.title': 'Nahrajte faktúru {order}',
  'requests.invoice_popup.items_title': 'Položky pre fakturáciu za {order}',
  'requests.invoice_popup.items_title_no_order': 'Položky pre fakturáciu',
  'requests.invoice_popup.total': 'Celkovo',
  'requests.invoice_popup.action.confirm': 'Potvrdiť žiadosť',
  'requests.invoice_popup.action.reject': 'Zamietnuť žiadosť',
  'requests.invoice_popup.action.submit': 'Odoslať',
  'requests.invoice_popup.select_item': 'Vyberte položku cenníka',

  'requests.invoice_popup.action_result.rejected': 'Požiadavka zamietnutá',
  'requests.invoice_popup.action_result.reject_failed': 'Zamietnutie požiadavky zlyhalo',

  'requests.invoice_popup.confirm_reject.title': 'Zamietnutie požiadavky',
  'requests.invoice_popup.confirm_reject.subtitle': 'Uveďte nepovinný dôvod zamietnutia požiadavky',
  'requests.invoice_popup.confirm_reject.button': 'Zamietnutie požiadavky',
  'requests.invoice_popup.confirm_reject.message': 'Naozaj zamietnuť požiadavku?',

  'requests.invoice_popup.details.created_at': 'Dátum vytvorenia',
  'requests.invoice_popup.details.created_by': 'Zadal',
  'requests.invoice_popup.details.confirmed_by': 'Potvrdil',
  'requests.invoice_popup.details.plate_number': 'Eč vozidla',
  'requests.invoice_popup.details.number_of_km': 'Počet km',
  'requests.invoice_popup.details.description': 'Popis',
  'requests.invoice_popup.details.distance': 'Vzdialenosť',
  'requests.invoice_popup.details.items_count': 'Počet položiek',
  'requests.invoice_popup.details.weight': 'Hmotnosť',

  'requests.invoice_popup.bonus.table.request': 'Požiadavka',
  'requests.invoice_popup.bonus.table.cooling': 'Chladenie',
  'requests.invoice_popup.bonus.table.display': 'Displej',
  'requests.invoice_popup.bonus.table.other': 'Ostatné',

  'requests.table.ordered_items': 'Objednané položky',

  /* FILTERS (GENERAL) */
  'filters.query.placeholder': 'Zadajte hľadaný výraz',
  'filters.status.placeholder': 'Stav',
  'filters.carrier.placeholder': 'Prepravca',
  'filters.warehouse.placeholder': 'Sklad',
  'filters.customer.placeholder': 'Odosielateľ',
  'filters.destination.placeholder': 'Príjemca',
  'filters.location.placeholder': 'Lokalita',
  'filters.from.placeholder': 'Od',
  'filters.to.placeholder': 'Do',

  /* TRANSPORTS */
  'transport.tabs.waiting.title': 'Plánovanie prepravy',
  'transport.tabs.waiting.hint': 'Aktuálne objednávky a plán zvozu',
  'transport.tabs.planned.title': 'Naplánované',
  'transport.tabs.planned.hint': 'Aktuálne prepravované objednávky',
  'transport.tabs.finished.title': 'Dokončené doručenia',
  'transport.tabs.finished.hint': 'Odvezené a zhodnotené objednávky',

  'transport.create_order.popup.title': 'Vytvorenie objednávky',
  'transport.create_order.popup.button': 'Potvrdiť prepravu',
  'transport.create_order.popup.label': 'Poznámka k preprave',

  /* PENDING TRANSPORTS */
  'transport.waiting.open.title': 'Zoznam otvorených požiadaviek',
  'transport.waiting.open.selected_count':
    '{count, plural, one {# vybraná} few {# vybrané} other {# vybraných}}',
  'transport.waiting.open.filter.kind': 'Typ zberu',
  'transport.waiting.open.filter.item_type': 'Typ položky',
  'transport.waiting.open.filter.location': 'Lokalita',
  'transport.waiting.open.filter.office_id': 'Odosielateľ',
  'transport.waiting.open.filter.carrier_id': 'Dopravca',
  'transport.waiting.open.table.sender': 'Odosielateľ',
  'transport.waiting.open.table.location': 'Mesto a adresa',
  'transport.waiting.open.table.note': 'Poznámka',
  'transport.waiting.open.table.created_at': 'Vytvorená',
  'transport.waiting.open.item.pieces': 'Celkový počet kusov',
  'transport.waiting.open.item.est_weight': 'Odhadovaná váha',
  'transport.waiting.open.item.created_at': 'Vytvorené',
  'transport.waiting.open.item.collection_sites':
    '{count, plural, one {# odberné miesto} few {# odberné miesta} other {# odberných miest}}',
  'transport.destination.transport_note_popup.title': 'Vytvorenie objednávky',
  'transport.destination.transport_note_popup.label': 'Poznámka k preprave',
  'transport.destination.transport_note_popup.submit': 'Potvrdiť prepravu',
  'transport.destination.filter1.title': 'Miesto doručenia a deň zvozu',
  'transport.destination.filter1.switch': 'Zobraziť rozšírené možnosti doručenia',
  'transport.destination.filter1.count': 'Celkový počet kusov',
  'transport.destination.filter1.add_line': 'Pridať ďaľší sklad',
  'transport.destination.filter1.distance': 'Vzdialenosť',
  'transport.destination.filter1.weight': 'Váha',
  'transport.destination.filter1.destination': 'Miesto doručenia',
  'transport.destination.filter1.date': 'Deň zvozu',
  'transport.waiting.plan.title': 'Odporúčaní dopravcovia',
  'transport.waiting.plan.existing': 'Na {date} je naplánovaný zvoz pre {location}',
  'transport.waiting.plan.add_to_existing': 'Zvážte doplánovanie do existujúcej objednávky.',
  'transport.waiting.plan.create': 'Vytvoriť objednávku',
  'transport.waiting.plan.table.name': 'Názov',
  'transport.waiting.plan.filter.region': 'Región',
  'transport.waiting.order_result.title': 'Vytvorenie objednávky - výsledok',
  'transport.waiting.order_result.message.success': 'Objednávka vytvorená',
  'transport.waiting.order_result.message.failure': 'Objednávku sa nepodarilo vytvoriť',
  'transport.waiting.order_result.button': 'OK',
  'transport.detail_popup.title': '{order} {status}',
  'transport.detail_popup.remove_popup.title': 'Zrušiť z prepravy',
  'transport.detail_popup.remove_popup.message': 'Zrušiť z prepravy?',
  'transport.detail_popup.form.submitted_by': 'Potvrdil',
  'transport.detail_popup.form.submitted_at': 'Dňa',
  'transport.detail_popup.form.note': 'Poznámka',
  'transport.detail_popup.form.save_changes': 'Uložiť zmeny',
  'transport.detail_popup.form.remove_from_transport': 'Zrušiť z prepravy',
  'transport.detail_popup.form.abort_changes': 'Zrušiť zmeny',
  'transport.detail_popup.actions.cancel': 'Zrušiť',
  'transport.detail_popup.actions.edit': 'Editovať',
  'transport.detail_popup.actions.accept_carrier': 'Potvrdiť',
  'transport.detail_popup.actions.vehicle': 'Vozidlo',
  'transport.vehicle_popup.form.vehicle': 'Vozidlo',
  'transport.vehicle_popup.form.license_plate': 'ŠPZ',
  'transport.vehicle_popup.form.initial_km': 'Počiaťočný stav km',
  'transport.vehicle_popup.title': 'Výber vozidla',
  'transport.vehicle_popup.button': 'Vybrať',
  'transport.waiting.no_order_description': 'Žiadny popis',

  'transport.detail_popup.detail_panel.created_at': 'Dátum vytvorenia',
  'transport.detail_popup.detail_panel.scheduled_at': 'Dátum prepravy',
  'transport.detail_popup.detail_panel.license_plate': 'EČ vozidla',
  'transport.detail_popup.detail_panel.distance': 'Odhad vzdialenosti',
  'transport.detail_popup.detail_panel.created_by': 'Zadal',
  'transport.detail_popup.detail_panel.kilometers': 'Počet km',
  'transport.detail_popup.detail_panel.items_count': 'Počet položiek',
  'transport.detail_popup.detail_panel.confirmed_by': 'Potvrdil',
  'transport.detail_popup.detail_panel.description': 'Popis',
  'transport.detail_popup.detail_panel.weight': 'Hmotnosť',
  'transport.detail_popup.detail_panel.plan.evaluation': 'Zhodnotenie',
  'transport.detail_popup.detail_panel.plan.stored': 'Uskladnenie',

  'transport.detail_popup.cancel_popup.title': 'Zrušiť objednávku',
  'transport.detail_popup.cancel_popup.label': 'Zadajte dôvod zrušenia objednávky',
  'transport.detail_popup.cancel_popup.button': 'Zrušiť objednávku',
  'transport.detail_popup.accepted_carrier.message': 'Objednávka prijatá prepravcom',
  'transport.detail_popup.error.offices_fetch_fail': 'Nepodarilo sa získať cieľové prevádzky',
  'transport.detail_popup.add_storage': 'Pridať vykládku',
  'transport.detail_popup.select_storage': 'Vyberte prevádzku',
  'transport.detail_popup.select_items': 'Vyberte položky do plánu',

  'transport.add_plan.form.office_id': 'Prevádzka',
  'transport.add_plan.form.scheduled_at': 'Dátum zvozu',
  'transport.add_plan.form.item_categories': 'Kategórie plánu',
  'transport.add_plan.form.description': 'Poznámka',
  'transport.add_plan.form.title': 'Pridanie nového plánu',

  'transport.planned.title': 'Zoznam naplánovaných objednávok',
  'transport.planned.table.status': 'Stav',
  'transport.planned.table.carrier': 'Prepravca',
  'transport.planned.table.transported': 'Prepravované',
  'transport.planned.table.orders_distance': 'Požiadavky a vzdialenosť',
  'transport.planned.table.customer': 'Odosielateľ',
  'transport.planned.table.estimated_price': 'Odhad ceny',
  'transport.planned.table.scheduled_at': 'Dátum prepravy',
  'transport.planned.table.created_at': 'Dátum vytvorenia',
  'transport.planned.table.pricing_status': 'Stav nacenenia',

  'transport.planned.filter.scheduled_at.from.placeholder': 'Preprava od',
  'transport.planned.filter.status.from.placeholder': 'Stav',

  'transport.finished.title': 'Zoznam dokončených objednávok',
  'transport.finished.table.status': 'Stav',
  'transport.finished.table.carrier': 'Prepravca',
  'transport.finished.table.content': 'Prepravované',
  'transport.finished.table.extent': 'Objednávky a vzdialenosť',
  'transport.finished.table.final_price': 'Finálna cena',
  'transport.finished.table.scheduled_at': 'Dátum prepravy',
  'transport.finished.table.created_at': 'Dátum vytvorenia',
  'transport.finished.filter.export_button': 'Exportovať OBJ bez FA',

  'transport.existing.add': 'Pridať do objednávky',
  'transport.existing.current': 'Aktuálne zvozy na plánovaný deň a sklad',

  'transport.groups_filter_popup.title': 'Skupiny pre objednanie',
  'transport.groups_filter_popup.submit': 'Potvrdiť',
  'transport.groups_filter_popup.all': 'Všetky',

  'storages.tabs.current.title': 'Aktuálne prijaté',
  'storages.tabs.current.hint': 'Ešte nespracované doručenia',
  'storages.tabs.waiting.title': 'Čakajúce na prepravu',
  'storages.tabs.waiting.hint': 'Ešte nespracované doručenia',
  'storages.tabs.finished.title': 'Uzavreté spracovania',
  'storages.tabs.finished.hint': 'Požiadavky, ktoré uz boli zhodnotené alebo odvezené',
  'storages.tabs.reports.title': 'Kvartálne hlásenia',
  'storages.tabs.reports.hint': 'Hlásenie spracovávateľa',
  'storages.tabs.button.create_transport': 'Objednať zvoz',
  'storages.card.title.current': 'Zoznam položiek aktuálne na sklade',
  'storages.card.title.waiting': 'Zoznam položiek čakajúcich na prepravu',
  'storages.card.title.finished': 'Zoznam zhodnotených alebo odvezených položiek',
  'storages.no_warehouse.popup.title': 'Vyberte sklad',
  'storages.no_warehouse.popup.message': 'Táto obrazovka je dostupná len správcom skladu',

  'storages.table.status': 'Stav',
  'storages.table.warehouse': 'Sklad',
  'storages.table.price_estimated': 'Odhad ceny',
  'storages.table.customer': 'Odosielateľ',
  'storages.table.totals': 'Objednané',
  'storages.table.carrier': 'Prepravca',
  'storages.table.created_at': 'Dátum prijatia',

  'storages.create_transfer.form.origin_id': 'Odkiaľ',
  'storages.create_transfer.form.request_type': 'Typ zvozu',
  'storages.create_transfer.form.request_type.request': 'Objednať zvoz',
  'storages.create_transfer.form.request_type.local': 'Vlastný presun',
  'storages.create_transfer.form.destination_id': 'Kam',
  'storages.create_transfer.form.scheduled_at': 'Dátum požiadavky',
  'storages.create_transfer.form.title': 'Vytvorenie požiadavky na prepravu',
  'storages.create_transfer.form.button': 'Potvrdiť požiadavku',

  'storages.pending.title': 'Čakajúce na prepravu',

  /* PARTNERS */
  'partners.tabs.partners.title': 'Subjekty',
  'partners.tabs.partners.hint': 'Manažment entít a cenníkov',
  'partners.tabs.users.title': 'Používatelia',
  'partners.tabs.users.hint': 'Manažment používateľov',
  'partners.tabs.button.create_partner': 'Pridať subjekt',
  'partners.tabs.button.export_partners': 'Exportovať subjekty',
  'partners.tabs.button.create_user': 'Pridať používateľa',
  'partners.tabs.button.export_users': 'Exportovať používateľov',

  'partner_popup.title.edit': 'Editácia',
  'partner_popup.title.create': 'Vytvorenie',
  'partner_popup.menu.basic': 'Základné údaje',
  'partner_popup.menu.sites': 'Prevádzky',
  'partner_popup.menu.transport': 'Preprava',
  'partner_popup.menu.add_site': 'Pridať prevádzku',
  'partner_popup.menu.site_details': 'Detaily prevádzky',
  'partner_popup.menu.site_create': 'Vytvorenie prevádzky',
  'partner_popup.menu.price_list_details': 'Cenník pre {name}',
  'partner_popup.menu.car_price_lists': '{plates} - Cenníky',
  'partner_popup.menu.car_details': 'Detail auta',
  'partner_popup.menu.transport_title': 'Preprava - autá',
  'partner_popup.menu.add_car': 'Pridať auto',
  'partner_popup.menu.add_car_price_list': 'Pridať cenník',
  'partner_popup.menu.car_price_list_detail': 'Detail cenníka auta',
  'partner_popup.menu.car_price_list_create': 'Nový cenník',
  'partner_popup.menu.limit_list_title': 'Limity',
  'partner_popup.menu.add_office_limit': 'Pridať limit',
  'partner_popup.menu.update_office_limit': 'Upraviť limit',
  'partner_popup.menu.additional_pricing': 'Cenník',
  'partner_popup.menu.bonus_price_lists': 'Bonusové cenníky',
  'partner_popup.partner_detail_form.name': 'Názov',
  'partner_popup.partner_detail_form.service_type': 'Doplnkové služby',
  'partner_popup.partner_detail_form.has_transport.hint': 'Subjekt je prepravca',
  'partner_popup.partner_detail_form.has_administration.hint': 'Subjekt je samospráva',
  'partner_popup.partner_detail_form.has_storages': 'Prevádzky a sklady',
  'partner_popup.partner_detail_form.has_transport': 'Preprava',
  'partner_popup.partner_detail_form.has_administration': 'Mobilný zber',
  'partner_popup.partner_detail_form.address': 'Ulica',
  'partner_popup.partner_detail_form.legal_form': 'Právna forma',
  'partner_popup.partner_detail_form.public_collections': 'Robí mobilný zber?',
  'partner_popup.partner_detail_form.districts': 'Okresy',
  'partner_popup.partner_detail_form.districts.district': 'Okres',
  'partner_popup.partner_detail_form.district': 'Okres',
  'partner_popup.partner_detail_form.city': 'Mesto',
  'partner_popup.partner_detail_form.zip': 'PSČ',
  'partner_popup.partner_detail_form.business_id': 'IČO',
  'partner_popup.partner_detail_form.tax_id': 'DIČ',
  'partner_popup.partner_detail_form.vat_id': 'IČ DPH',
  'partner_popup.partner_detail_form.contact_person': 'Štatutár/konateľ',
  'partner_popup.partner_detail_form.email': 'Email',
  'partner_popup.partner_detail_form.phone': 'Telefón',
  'partner_popup.partner_detail_form.add_phone': 'Pridať tel. č.',
  'partner_popup.partner_detail_form.contracts': 'Zmluvy',
  'partner_popup.partner_detail_form.contract_date': 'Dátum zmluvy',
  'partner_popup.partner_detail_form.contract_document': 'Nahrať zmluvu',
  'partner_popup.partner_detail_form.contract_note': 'Poznámka k zmluve',
  'partner_popup.partner_detail_form.confirm_required': 'Musí potvrdzovať dopravcovi prebratie?',
  'partner_popup.partner_detail_form.add_contract': 'Pridať zmluvu',
  'partner_popup.partner_detail_form.contract.date': 'Dátum',
  'partner_popup.partner_detail_form.contract.document': 'Dokument',
  'partner_popup.partner_detail_form.contract.note': 'Poznámka',
  'partner_popup.partner_detail_form.contract.amendments': 'Prílohy',
  'partner_popup.partner_detail_form.contract.add_amendment': 'Pridať prílohu',
  'partner_popup.partner_detail_form.stamp': 'Pečiatka',
  'partner_popup.partner_detail_form.has_slno': 'SLNO',
  'partner_popup.partner_detail_form.slno_number': 'Číslo súhlasu SLNO',
  'partner_popup.partner_detail_form.validity_date': 'Dátum platnosti',
  'partner_popup.partner_detail_form.slno_code': 'Číslo súhlasu SLNO',
  'partner_popup.partner_detail_form.slno_expires_at': 'Dátum platnosti',
  'partner_popup.partner_detail_form.slno_district_office': 'Okresný úrad',
  'partner_popup.partner_detail_form.slno_document': 'Dokument',
  'partner_popup.partner_detail_form.slno_processing_numbers': 'Kódy spracovania',
  'partner_popup.partner_detail_form.slno_processing_numbers_hint':
    'Jednotlivé kódy oddeľte čiarkami',
  'partner_popup.partner_detail_form.is_vat_payer': 'Platca DPH',
  'partner_popup.partner_detail_form.result_updated': 'Subjekt aktualizovaný',
  'partner_popup.partner_detail_form.result_created': 'Subjekt vytvorený',
  'partner_popup.partner_detail_form.group_contract_amendments': 'Dodatky k zmluvám',
  'partner_popup.partner_detail_form.appendix.date': 'Dátum dodatku',
  'partner_popup.partner_detail_form.appendix.document': 'Dokument',
  'partner_popup.partner_detail_form.appendix.note': 'Poznámka',

  'partner_popup.partner_detail_form.errors.zip_5_chars': 'PSČ musí mať max. 5 znakov',

  'partner_popup.car_price_lists.table.origin_regions': 'Východzie regióny',
  'partner_popup.car_price_lists.table.destination_regions': 'Cieľové regióny',
  'partner_popup.car_price_lists.table.type': 'Typ',
  'partner_popup.car_price_lists.table.items': 'Naceňovanie',
  'partner_popup.car_price_lists.table.is_main': 'Prioritný',
  'partner_popup.car_price_lists.table.is_active': 'Aktívny',

  'partner_popup.sites_list.table.office': 'Prevádzka',
  'partner_popup.sites_list.table.type': 'Typ',
  'partner_popup.sites_list.table.contact_person': 'Kontaktná osoba',
  'partner_popup.sites_list.table.price_list': 'Typ cenníka',
  'partner_popup.sites_list.price_list_type.none': 'Nemá',
  'partner_popup.sites_list.price_list_type.weight': 'za váhu',
  'partner_popup.sites_list.price_list_type.amount': 'za kusy',

  'partner_popup.site_details.name': 'Názov',
  'partner_popup.site_details.address': 'Adresa',
  'partner_popup.site_details.zip': 'PSČ',
  'partner_popup.site_details.city': 'Mesto',
  'partner_popup.site_details.office_kind': 'Typ prevádzky',
  'partner_popup.site_details.description': 'Popis',
  'partner_popup.site_details.contact_person': 'Meno kontaktnej osoby',
  'partner_popup.site_details.email': 'Email',
  'partner_popup.site_details.phone': 'Telefón',
  'partner_popup.site_details.is_autonomous': 'Autonómna',
  'partner_popup.site_details.is_primary': 'Primárna',
  'partner_popup.site_details.is_primary.hint':
    'Určuje prevádzku, v ktorej používatel po prihlásení operuje',
  'partner_popup.site_details.is_exempt_autonomous': 'Špeciálna obsluha',
  'partner_popup.site_details.is_exempt_autonomous.hint': 'Vyňatie z autonomného regiónu',
  'partner_popup.site_details.is_weighting': 'Váženie',
  'partner_popup.site_details.is_weighting.hint': 'Váženie',
  'partner_popup.site_details.parent_id': 'Prekládka pre',
  'partner_popup.site_details.group_slno': 'Čisla súhlasu',
  'partner_popup.site_details.is_exempt_bonus': 'Neuplatňovať bonus',
  'partner_popup.site_details.hint': 'Vyňatie z bonusov',

  'partner_popup.price_list.has_flat_rate': 'Mesačný paušál',
  'partner_popup.price_list.abra_codes': 'ABRA KÓDY ak 0 položiek (účtovne)',
  'partner_popup.price_list.add_abra_code': 'Pridať ďaľší kód',

  'partner_popup.car_list.table.car': 'Auto',
  'partner_popup.car_list.table.capacity': 'Nosnosť',
  'partner_popup.car_list.table.dimensions': 'Rozmery',
  'partner_popup.car_list.table.regions': 'Regióny',
  'partner_popup.car_list.table.dimensions_format': '{length}x{width}x{height}',
  'partner_popup.car_list.delete_popup.message':
    'Naozaj chcete odstrániť vozidlo {description} {plates}?',

  'partner_popup.car_detail.form.description': 'Popis',
  'partner_popup.car_detail.form.plate': 'ŠPZ',
  'partner_popup.car_detail.add_license_plate': 'Pridať ŠPZ',
  'partner_popup.car_detail.form.legal_payload': 'Písaná nosnosť (kg)',
  'partner_popup.car_detail.form.real_payload': 'Reálna nosnosť (kg)',
  'partner_popup.car_detail.form.office': 'Prevádzka',
  'partner_popup.car_detail.form.height': 'Výška (cm)',
  'partner_popup.car_detail.form.width': 'Šírka (cm)',
  'partner_popup.car_detail.form.length': 'Dĺžka (cm)',

  'partner_popup.car_price_list_detail.form.type': 'Typ položiek',
  'partner_popup.car_price_list_detail.form.kind': 'Typ cenníka',
  'partner_popup.car_price_list_detail.form.price_per_km': '€ / km',
  'partner_popup.car_price_list_detail.form.is_priority': 'Je prioritný',
  'partner_popup.car_price_list_detail.form.is_active': 'Je aktívny',
  'partner_popup.car_price_list_detail.form.region.location': 'Lokalita',
  'partner_popup.car_price_list_detail.form.region.is_autonomous': 'Je autonómny?',
  'partner_popup.car_price_list_detail.form.region.days': 'Dní na zvoz',
  'partner_popup.car_price_list_detail.form.region_origins': 'Regióny z',
  'partner_popup.car_price_list_detail.form.regions': 'Regióny',
  'partner_popup.car_price_list_detail.form.search_placeholder': 'Vyhľadať',
  'partner_popup.car_price_list_detail.form.region_destinations': 'Miesto doručenia',
  'partner_popup.car_price_list_detail.form.extra_items_name': 'Extra položky',
  'partner_popup.car_price_list_detail.form.extra_items_name_placeholder': 'Názov',
  'partner_popup.car_price_list_detail.form.extra_items_price': 'Cena za ks',
  'partner_popup.car_price_list_detail.form.extra_items_abra_code': 'Kód v ABRA',
  'partner_popup.car_price_list_detail.form.price_lists': 'Cenníky',

  'partner_popup.limits.create.button': 'Uložiť',
  'partner_popup.limits.update.button': 'Uložiť',

  'partner_popup.additional_pricing.title': 'Dodatočné položky cenníkov',
  'partner_popup.menu.add_pricing_item': 'Pridať položku',
  'partner_popup.additional_pricing.table.name': 'Názov',
  'partner_popup.additional_pricing.table.abra_code': 'ABRA kód',
  'partner_popup.additional_pricing.table.price_recommended': 'Odporúčaná cena',
  'partner_popup.additional_pricing.title_create': 'Nová položka cenníka',
  'partner_popup.additional_pricing.title_update': 'Upraviť položku cenníka',
  'partner_popup.additional_pricing.form.name': 'Názov',
  'partner_popup.additional_pricing.form.abra_code': 'ABRA kód',
  'partner_popup.additional_pricing.form.price_recommended': 'Odporúčaná cena',
  'partner_popup.additional_pricing.delete_message': 'Naozaj odstrániť položku cenníka?',
  'partner_popup.additional_pricing.delete_error': 'Nepodarilo sa odstrániť položku cenníka',

  'partner_popup.bonus_pricing_list.title': 'Bonusové cenníky',
  'partner_popup.bonus_pricing_list.table.name': 'Názov',
  'partner_popup.bonus_pricing_list.table.created_at': 'Dátum vytvorenia',
  'partner_popup.bonus_pricing_detail.title': 'Detail bonusového cenníka',
  'partner_popup.bonus_pricing_detail.name': 'Názov',
  'partner_popup.bonus_pricing_detail.total_weight': 'Celková váha',

  'user_popup.menu.basic': 'Základné údaje',
  'user_popup.menu.assignments': 'Prevádzky a role',
  'user_popup.menu.add_role': 'Pridať rolu',
  'user_popup.menu.update_roles': 'Upraviť role',
  'user_popup.basic_details.form.name': 'Meno',
  'user_popup.basic_details.form.surname': 'Priezvisko',
  'user_popup.basic_details.form.is_staff': 'Prístup do systému',
  'user_popup.basic_details.form.is_staff.inactive': 'Neaktívny',
  'user_popup.basic_details.form.is_staff.active': 'Aktívny',
  'user_popup.basic_details.form.email': 'Email',
  'user_popup.basic_details.form.phone': 'Telefón',
  'user_popup.basic_details.form.password1': 'Heslo',
  'user_popup.basic_details.form.password2': 'Potvrdenie hesla',
  'user_popup.basic_details.form.street': 'Ulica',
  'user_popup.basic_details.form.city': 'Mesto',
  'user_popup.basic_details.form.postal_code': 'PSČ',

  'user_popup.sites_and_roles.table.office': 'Prevádzka',
  'user_popup.sites_and_roles.table.type': 'Typ',
  'user_popup.sites_and_roles.table.role': 'Rola',
  'user_popup.sites_and_roles.table.role.none': 'Nemá',

  'user_popup.title.creating': 'Nový používateľ',
  'user_popup.title.loading': 'Načítavam',

  'user_popup.add_role.group_id': 'Rola',
  'user_popup.add_role.office_id': 'Prevádzka',
  'user_popup.add_role.is_primary': 'Primárna',

  'user_popup.roles.title': '',

  'subjects.title': 'Zoznam subjektov',
  'subjects.table.status': 'Stav',
  'subjects.table.subject_type': 'Typ subjektu',
  'subjects.table.name': 'Názov',
  'subjects.table.business_ids': 'IČO a DIČ',
  'subjects.table.locations': 'Prevádzky',
  'subjects.table.contact': 'Kontaktná osoba',
  'subjects.table.order_summary': 'Sumár objednávok',
  'subjects.table.invoiced_amount': 'Odfakturovaná suma',

  'subjects.filter.subject_type': 'Typ subjektu',
  'subjects.filter.region': 'Región',

  'subjects.type.is_carrier': 'Prepravca',
  'subjects.type.is_office': 'Prevádzka',
  'subjects.type.is_administration': 'Správca',
  'subjects.type.is_staff': 'Zamestnanec',
  'subjects.type.is_slno': 'SLNO',

  'price_list_item.add_range': 'Pridať ďalší rozsah',
  'price_list_item.errors.per_piece_invalid': 'Zadajte platnú cenu za kus',
  'price_list_item.errors.per_kg_invalid': 'Zadajte platnú cenu za kilogram',
  'price_list_item.extra_price_invalid_pc': 'Zadajte platnú cenu a hraničné množstvo',
  'price_list_item.extra_price_invalid_kg': 'Zadajte platnú cenu a hraničnú hmotnosť',

  'users.filter.subject': 'Subjekt',
  'users.filter.status': 'Stav',
  'users.filter.role': 'Rola',

  'users.title': 'Zoznam používateľov',

  'users.table.status': 'Stav',
  'users.table.contact': 'Kontaktná osoba',
  'users.table.subject': 'Názov subjektu',
  'users.table.last_login': 'Posledné prihlásenie',

  'lists.title': 'Číselníky',
  'lists.tabs.postal_codes.title': 'Zoznam PSČ',
  'lists.tabs.postal_codes.hint': 'Upravovateľný zoznam PSČ',
  'lists.tabs.regions.title': 'Regióny',
  'lists.tabs.regions.hint': 'Zoznam existujúcich regiónov',
  'lists.tabs.button.add_postal_code': 'Pridať PSČ',
  'lists.tabs.documents.title': 'Dokumenty',
  'lists.tabs.documents.hint': 'Nastavenia statických dokumentov',
  'lists.tabs.offices.title': 'Prevádzky',
  'lists.tabs.offices.hint': 'Umožňuje prepínanie medzi prevádzkami',
  'lists.tabs.item_categories.title': 'Kategórie',
  'lists.tabs.item_categories.hint': 'Zoznam kategórií odpadu',
  'lists.tabs.item_groups.title': 'Skupiny',
  'lists.tabs.item_groups.hint': 'Skupiny kategórií odpadu',
  'lists.tabs.global_limits.title': 'Limity',
  'lists.tabs.global_limits.hint': 'Pre Envidom a štát',
  'lists.tabs.button.create_group': 'Vytvoriť skupinu',
  'lists.tabs.button.create_limit': 'Vytvoriť limit',
  'lists.tabs.catalog_numbers.title': 'Katalóg',
  'lists.tabs.catalog_numbers.hint': 'Katalógové čísla',
  'lists.tabs.button.create_catalog_number': 'Pridať číslo',
  'lists.tabs.button.create_item_category': 'Pridať kategóriu odpadu',
  'lists.tabs.button.create_additional_pricing': 'Vytvoriť položku',
  'lists.tabs.additional_pricing.title': 'Cenník',
  'lists.tabs.additional_pricing.hint': 'Dodatočné položky cenníkov',
  'lists.tabs.legal_forms.title': 'Právne formy',
  'lists.tabs.legal_forms.hint': 'Zoznam druhov právnych formiem subjektov',
  'lists.tabs.button.create_legal_form': 'Pridať právnu formu',
  'lists.tabs.office_kinds.title': 'Typy prevádzok',
  'lists.tabs.office_kinds.hint': 'Zoznam typov prevádzok',
  'lists.tabs.button.create_office_kind': 'Pridať typ prevádzky',
  'lists.tabs.bonus_price_lists.title': 'Bonusové cenníky',
  'lists.tabs.bonus_price_lists.hint': 'Zoznam bonusových cenníkov',
  'lists.tabs.button.create_bonus_price_list': 'Vytvoriť bonusový cenník',

  'lists.documents.form.ilno': 'Identifikačný list nebezpečného odpadu',
  'lists.documents.form.hp': 'Havarijný plán',
  'lists.documents.form.adr': 'Pokyny k ADR',
  'lists.documents.form.upload': 'Nahrať',
  'lists.documents.form.download': 'Stiahnuť',
  'lists.documents.uploaded.message': 'Dokument uložený',

  'item_categories.title': 'Kategórie odpadu',
  'item_categories.delete.message': 'Odstrániť kategóriu odpadu?',
  'item_categories.delete.error': 'Nepodarilo sa odstrániť kategóriu odpadu',
  'lists.item_categories.table.name': 'Názov',
  'lists.item_categories.table.short_name': 'Skratka',
  'lists.item_categories.table.abra_code': 'ABRA kód',
  'lists.item_categories.table.item_code': 'Kód položky',
  'lists.item_categories.table.weight': 'Hmotnosť',
  'lists.item_categories.table.is_hazardous': 'Nebezpečný odpad',
  'lists.item_categories.table.item_group': 'Skupina',
  'lists.item_categories.table.catalog_number': 'Katalógové číslo',
  'lists.item_categories.form.name': 'Názov',
  'lists.item_categories.form.short_name': 'Skratka',
  'lists.item_categories.form.abra_code': 'ABRA kód',
  'lists.item_categories.form.item_code': 'Kód položky',
  'lists.item_categories.form.weight': 'Hmotnosť',
  'lists.item_categories.form.is_hazardous': 'Nebezpečný odpad',
  'lists.item_categories.form.item_group': 'Skupina',
  'lists.item_categories.form.catalog_number': 'Katalógové číslo',
  'lists.item_categories.form.classification': 'Klasifikácia',
  'lists.item_categories.form.sequence': 'Poradie',
  'lists.item_categories.popup.title': 'Kategória odpadu',
  'lists.item_categories.popup.button': 'Uložiť',

  'lists.item_groups.title': 'Skupiny kategórií odpadu',
  'lists.item_groups.table.name': 'Názov',
  'lists.item_groups.table.item_categories': 'Kategórie odpadu',
  'lists.item_groups.table.kind': 'Typ',
  'lists.item_groups.table.sequence': 'Poradie',
  'lists.item_groups.table.created_at': 'Dátum vytvorenia',
  'lists.item_groups.form.name': 'Názov',
  'lists.item_groups.form.short_name': 'Skratka',
  'lists.item_groups.form.kind': 'Typ',
  'lists.item_groups.form.sequence': 'Poradie',
  'lists.item_groups.form.item_categories': 'Kategórie odpadu',
  'lists.item_groups.form.item_categories.placeholder': 'Vyberte možnosti',
  'lists.item_groups.form.coordination_center_share': 'Zberový podiel KC',
  'lists.item_groups.form.current_estimate': 'Odhad aktuálny rok',
  'lists.item_groups.form.envidom_budget': 'Budget ENVIDOM',
  'lists.item_groups.form.error.no_categories': 'Aspoň jedna kategória musí byť zadaná',
  'lists.item_groups.popup.title': 'Skupina kategórií odpadu',
  'lists.item_groups.popup.button': 'Uložiť',
  'lists.item_groups.delete.message': 'Odstrániť skupinu kategórií?',
  'lists.item_groups.delete.error': 'Nepodarilo sa odstrániť skupinu',

  'lists.global_limits.envidom.title': 'Limity Envidom',
  'lists.global_limits.governmental.title': 'Limity štátne',
  'lists.global_limits.table.item_group': 'Skupina',
  'lists.global_limits.table.limit': 'Limit',
  'lists.global_limits.table.year': 'Rok',
  'lists.global_limits.table.name': 'Názov',
  'lists.global_limits.table.sequence': 'Poradie',
  'lists.global_limits.table.created_at': 'Dátum vytvorenia',

  'lists.additional_pricing.table.name': 'Názov',
  'lists.additional_pricing.table.abra_code': 'ABRA kód',
  'lists.additional_pricing.table.price_recommended': 'Odporúčaná cena',
  'lists.additional_pricing.form.name': 'Názov',
  'lists.additional_pricing.form.abra_code': 'ABRA kód',
  'lists.additional_pricing.form.price_recommended': 'Odporúčaná cena',
  'lists.additional_pricing.form.title': 'Dodatočná položka cenníka',
  'lists.additional_pricing.form.button': 'Uložiť',
  'lists.additional_pricing.delete.error': 'Nepodarilo sa odstrániť položku',
  'lists.additional_pricing.delete.message': 'Naozaj odstrániť položku dodatočného nacenenia?',
  'lists.additional_pricing.title': 'Dodatočné položky cenníkov',

  'legal_forms.title': 'Právne formy',
  'lists.legal_forms.table.name': 'Názov',
  'lists.legal_forms.form.name': 'Názov',
  'lists.legal_forms.delete.error': 'Nepodarilo sa odstrániť právnu formu',
  'lists.legal_forms.delete.message': 'Odstrániť právnu formu?',
  'lists.legal_forms.popup.title': 'Detaily právnej formy',
  'lists.legal_forms.popup.button': 'Uložiť',

  'lists.bonus_price_lists.title': 'Bonusové cenníky',
  'lists.bonus_price_lists.table.name': 'Názov',
  'lists.bonus_price_lists.table.kind': 'Druh',
  'lists.bonus_price_lists.table.created_at': 'Dátum vytvorenia',
  'lists.bonus_price_lists.table.subjects': 'Subjekty',
  'lists.bonus_price_lists.delete_confirm': 'Skutočne odstrániť bonusový cenník?',
  'lists.bonus_price_lists.delete_error': 'Bonusový cenník sa nepodarilo odstrániť',
  'lists.bonus_price_popup.title.create': 'Nový bonusový cenník',
  'lists.bonus_price_popup.title.update': 'Upraviť bonusový cenník',
  'lists.bonus_price_popup.submit': 'Uložiť',
  'lists.bonus_price_popup.form.name': 'Názov',
  'lists.bonus_price_popup.form.subjects': 'Subjekty',
  'lists.bonus_price_popup.form.kind': 'Druh',
  'lists.bonus_price_popup.form.price_list': 'Cenník',
  // 'lists.bonus_price_popup.form.price_list_classification': 'Cenník - klasifikácia',
  // 'lists.bonus_price_popup.form.price_list_two_dimensional':
  //   'Cenník - Dvoj dimenzionálna lookup tabuľka',
  'lists.bonus_price_popup.form.pricing.name.A': 'Bonus A',
  'lists.bonus_price_popup.form.pricing.name.B': 'Bonus B',
  'lists.bonus_price_popup.form.pricing.name.C': 'Bonus C',
  'lists.bonus_price_popup.form.pricing.classification.cooling': 'Chladenie',
  'lists.bonus_price_popup.form.pricing.classification.display': 'Displeje',
  'lists.bonus_price_popup.form.pricing.classification.other': 'Ostatné',
  'lists.bonus_price_popup.form.pricing.other.amount': 'ostatné ks',
  'lists.bonus_price_popup.form.pricing.other.weight': 'ostatné kg',
  'lists.bonus_price_popup.form.pricing.add_button': 'Pridať ďalší rozsah',

  'limits.delete.message': 'Odstrániť vybraný limit?',
  'limits.delete.error': 'Nepodarilo sa odstrániť limit',

  'postal_codes.title': 'Zoznam PSČ',
  'postal_codes.table.code': 'PSČ',
  'postal_codes.form.code': 'PSČ',
  'postal_codes.form.title': 'Úprava PSČ',
  'postal_codes.form.submit': 'Uložiť',
  'postal_codes.delete.confirm': 'Naozaj odstrániť PSČ?',
  'postal_codes.delete.success': 'PSČ odstránené',
  'postal_codes.delete.error': 'PSČ sa nepodarilo odstrániť',

  'regions.title': 'Regióny',
  'regions.table.name': 'Názov',
  'regions.table.subject': 'Subjekt',

  'offices.toggle.title': 'Dostupné prevádzky',
  'offices.toggle.button': 'Prepnúť prevádzku',

  'limits.create_popup.button': 'Uložiť',
  'limits.create_popup.title': 'Vytvoriť limit',
  'limits.create_popup.sub_title.envidom': 'Limit pre Envidom',
  'limits.create_popup.sub_title.governmental': 'Štátny limit',

  'limits.edit_popup.button': 'Uložiť',
  'limits.edit_popup.title': 'Upraviť limit',
  'limits.edit_popup.subtitle.envidom': 'Limit pre Envidom',
  'limits.edit_popup.subtitle.governmental': 'Štátny limit',

  'limits.form.item_group_id': 'Skupina',
  'limits.form.name': 'Názov',
  'limits.form.year': 'Rok',
  'limits.form.limit': 'Limit',
  'limits.form.kind': 'Limit pre',
  'limits.form.sequence': 'Poradie',

  'export.button.title': 'Exportovať',
  'export.modal.request_obj.title': 'Export požiadavky',
  'export.modal.office.title': 'Export subjektov',
  'export.modal.user.title': 'Export používateľov',

  'reports.tabs.slno_reports.title': 'Dokumenty',
  'reports.tabs.slno_reports.hint': 'SLNO',
  'reports.tabs.button.create_ovo': 'Vytvoriť ohlásenie o vzniku odpadu a nakladaní s ním',
  'reports.tabs.button.create_elo': 'Vytvoriť evidenčný list odpadov',
  'reports.tabs.button.create_processed_report': 'Evidencia spracovaného odpadu',
  'reports.tabs.button.create_management_report': 'Hlásenie manažmentu',

  'slno_reports.title': 'Zoznam SLNO',
  'slno_reports.table.status': 'Stav',
  'slno_reports.table.customer': 'Objednávateľ',
  'slno_reports.table.carrier': 'Prepravca',
  'slno_reports.table.unload_site': 'Miesto vyloženia',
  'slno_reports.table.summary': 'Sumár objednávky',
  'slno_reports.table.page': 'strana',
  'slno_reports.table.transported_at': 'Dátum prepravy',
  'slno_reports.table.unloaded_at': 'Dátum vyloženia',

  'slno_reports.filter.customer': 'Objednávateľ',
  'slno_reports.filter.carrier': 'Prepravca',
  'slno_reports.filter.unload_site': 'Miesto vyloženia',
  'slno_reports.filter.created_at.from.placeholder': 'Vytv. od',
  'slno_reports.filter.created_at.to.placeholder': 'Do',

  'slno_reports.create_ovo_popup.title': 'Vytvoriť ohlásenie o vzniku odpadu a nakladaní s ním',
  'slno_reports.create_ovo_popup.button': 'Vytvoriť',
  'slno_reports.create_ovo_popup.form.year': 'Rok',
  'slno_reports.create_ovo_popup.form.office_id': 'Odosielateľ',

  'slno_reports.create_elo_popup.title': 'Evidenčný list odpadov',
  'slno_reports.create_elo_popup.button': 'Vytvoriť',
  'slno_reports.create_elo_popup.form.range': 'Rozsah',
  'slno_reports.create_elo_popup.form.office_id': 'Odosielateľ',
  'slno_reports.create_elo_popup.form.catalog_number_id': 'Katalógové číslo',

  'slno_reports.create_processed_popup.title': 'Evidencia spracovaného odpadu',
  'slno_reports.create_processed_popup.button': 'Vytvoriť',
  'slno_reports.create_processed_popup.form.year': 'Rok',

  'slno_reports.create_management_report_popup.title': 'Hlásenie manažmentu',
  'slno_reports.create_management_report_popup.button': 'Vytvoriť',
  'slno_reports.create_management_report_popup.form.year': 'Rok',

  'catalog_numbers.title': 'Katalógové čísla',
  'catalog_numbers.table.name': 'Názov',
  'catalog_numbers.table.code': 'Kód',
  'catalog_numbers.table.sequence': 'Poradie',
  'catalog_numbers.table.created_at': 'Dátum vytvorenia',
  'catalog_numbers.form.title': 'Katalógové číslo',
  'catalog_numbers.form.name': 'Názov',
  'catalog_numbers.form.code': 'Kód',
  'catalog_numbers.form.is_hazardous': 'Nebezpečný odpad',
  'catalog_numbers.form.y_code': 'Y-kód',
  'catalog_numbers.form.adr_idr': 'Zatriedenie',
  'catalog_numbers.form.un_number': 'UN číslo',
  'catalog_numbers.form.cover_kind': 'Druh obalu',
  'catalog_numbers.form.r_d_v_code': 'RDV kód',
  'catalog_numbers.form.waste_category': 'Kategória odpadu',
  'catalog_numbers.form.dangerous_class': 'Trieda nebezpečnosti',
  'catalog_numbers.form.physical_properties': 'Fyzikálne vlastnosti',
  'catalog_numbers.form.sequence': 'Poradie',
  'catalog_numbers.form.submit': 'Uložiť',
  'catalog_numbers.delete.message': 'Odstrániť katalógové číslo?',
  'catalog_numbers.delete.error': 'Nepodarilo sa odstrániť katalógové číslo',

  'pricing.request.title': 'Nacenenie požiadavky {request}',
  'pricing.request.details.created_at': 'Naložené',
  'pricing.request.details.kind': 'Typ',
  'pricing.request.details.customer_name': 'Odosielateľ',
  'pricing.request.details.address': 'Adresa',
  'pricing.request.details.customer_contact_number': 'Kontakt',
  'pricing.request.details.order_amount': 'Počet',
  'pricing.request.details.order': 'Objednávka',
  'pricing.request.details.order_weight': 'Hmotnosť',
  'pricing.request.details.desc_req': 'Poznámka',
  'pricing.request.details.desc_rec': 'Poznámka.',

  'pricing.order.details.created_at': 'Vytvorená',
  'pricing.order.details.scheduled_at': 'Naložená',
  'pricing.order.details.created_by': 'Vytvoril(a)',
  'pricing.order.details.license_plate': 'ŠPZ',
  'pricing.order.details.distance': 'Vzdialenosť',
  'pricing.order.details.duration': 'Trvanie',
  'pricing.order.details.amount': 'Množstvo',
  'pricing.order.details.description': 'Popis',
  'pricing.order.details.weight': 'Hmotnosť',
  'pricing.order.title': 'Nacenenie objednávky {order}',

  'pricing.warehouse_log.title': 'Nacenenie uskladnenia',
  'pricing.warehouse_log.details.created_at': 'Vytvorené',
  'pricing.warehouse_log.details.carrier': 'Prepravca',
  'pricing.warehouse_log.details.destination': 'Cieľ',
  'pricing.warehouse_log.details.order_name': 'Objednávka',
  'pricing.warehouse_log.details.total_amount': 'Množstvo',
  'pricing.warehouse_log.details.total_weight': 'Hmotnosť',

  'pricing_popup.reject_reason': 'Dôvod zamietnutia',
  'pricing_popup.form.price_id': 'Položka',
  'pricing_popup.form.amount': 'Množstvo',
  'pricing_popup.form.price': 'Cena',
  'pricing_popup.form.description': 'Poznámka',
  'pricing_popup.form.distance_item': 'Vzdialenosť',

  'pricing_popup.proposed_price': 'Celkovo',
  'pricing_popup.action.confirm': 'Potvrdiť žiadosť',
  'pricing_popup.pricing_table.title': 'Cenník',

  'static_pricing.table.name': 'Názov',
  'static_pricing.table.amount': 'Množstvo',
  'static_pricing.table.price': 'Cena',
  'static_pricing.table.created_at': 'Vytvorené',
  'static_pricing.table.distance_item': 'Vzdialenosť',

  'pricing.reject_popup.title': 'Zamietnutie nacenenia',
  'pricing.reject_popup.label': 'Zadajte voliteľný dôvod zamietnutia',
  'pricing.reject_popup.button': 'Zamietnuť',

  'pricing_approval_popup.approve_success': 'Nacenenie prijaté',
  'pricing_approval_popup.approve_failure': 'Zlyhalo schválenie nacenenia',
  'pricing_approval_popup.reject_success': 'Nacenenie zamietnuté',
  'pricing_approval_popup.reject_failure': 'Zlyhalo zamietnutie nacenenia',
  'pricing_approval_popup.actions.approve': 'Schváliť',
  'pricing_approval_popup.actions.reject': 'Zamietnuť',
  'pricing_approval_popup.title.request': 'Schválenie nacenenia požiadavky',
  'pricing_approval_popup.note.requester': 'Poznámka žiadateľa',
  'pricing_approval_popup.title.recipient': 'Poznámka prijímateľa',

  'invoicing_popup.requests.title': 'Vytvorenie faktúry',
  'invoicing_popup.title': 'Nová faktúra',
  'invoicing_popup.footer.total_price': 'Celkovo',
  'invoicing_popup.footer.submit': 'Odoslať',
  'invoicing_popup.footer.next': 'Ďalej',
  'invoicing_popup.footer.prev': 'Späť',
  'invoicing_popup.filter.created_at.placeholder': 'Vytvorená',
  'invoicing_popup.table.object': 'Predmet',
  'invoicing_popup.table.subject': 'Subjekt',
  'invoicing_popup.table.address': 'Mesto a adresa',
  'invoicing_popup.table.note': 'Poznámka',
  'invoicing_popup.table.created_at': 'Vytvorené',
  'invoicing_popup.item.items_total': 'Suma za objednávku',
  'invoicing_popup.item.total': 'Celkovo',
  'invoicing_popup.file_upload.hint':
    'Potiahnutím súboru alebo kliknutím nahrajte faktúru vo formáte PDF',
  'invoicing_popup.form.due_date': 'Dátum splatnosti',
  'invoicing_popup.form.variable_symbol': 'Variabilný symbol',
  'invoicing_popup.form.external': 'Číslo faktúry',

  'invoices.title': 'Zoznam faktúr',
  'invoices.tabs.invoices.title': 'Faktúry',
  'invoices.tabs.invoices.hint': 'Zoznam faktúr',
  'invoices.tabs.button.create_invoice': 'Vytvoriť faktúru',

  'invoices.filter.status': 'Stav',
  'invoices.filter.subject': 'Subjekt',
  'invoices.filter.customer': 'Odosielateľ',
  'invoices.filter.accepted_at.from.placeholder': 'Schválené od',
  'invoices.filter.accepted_at.to.placeholder': 'Do',
  'invoices.filter.due_date.from.placeholder': 'Splatnosť od',
  'invoices.filter.due_date.to.placeholder': 'Do',
  'invoices.filter.export_all': 'Stiahnuť všetky FA',

  'invoices.table.status': 'Stav',
  'invoices.table.subject': 'Subjekt',
  'invoices.table.type': 'Typ',
  'invoices.table.orders': 'Požiadavky a objednávky',
  'invoices.table.total': 'Suma',
  'invoices.table.summary': 'Sumár faktúry',
  'invoices.table.accepted_at': 'Dátum potvrdenia',
  'invoices.table.due_date': 'Dátum splatnosti',
  'invoices.table.created_at': 'Dátum vytvorenia',

  'profile.tabs.profile.title': 'Profil',
  'profile.tabs.profile.hint': 'Detaily používateľa',

  'profile.change_password.button': 'Zmeniť heslo',
  'profile.change_password.title': 'Zmena hesla',
  'profile.change_password.old_password': 'Aktuálne heslo',
  'profile.change_password.new_password1': 'Nové heslo',
  'profile.change_password.new_password2': 'Zopakujte nové heslo',
  'profile.change_password.submit': 'Zmeniť',
  'profile.change_password.message.mismatch': 'Opakované nové heslo sa nezhoduje',
  'profile.change_password.message.success': 'Heslo bolo zmenené',
  'profile.change_password.message.failure': 'Heslo sa nepodarilo zmeniť',

  'controls.pieces': 'ks',
  'controls.kg': 'kg',
  'controls.yes': 'Áno',
  'controls.ok': 'OK',
  'controls.download': 'Stiahnuť',
  'controls.pieces_full': '{count, plural, one {# kus} few {# kusy} other {# kusov}}',
  'controls.requests':
    '{count, plural, one {# požiadavka} few {# požiadavky} other {# požiadaviek}}',
  'controls.eur_per_kg': '€ / KG',
  'controls.eur_per_pc': '€ / KS',
  'controls.price_other_pc': 'ostatné kusy',
  'controls.price_amount_separator': 'do (ks)',
  'controls.price_weight_separator': 'do (kg)',
  'controls.no': 'Nie',
  'controls.confirm': 'Potvrdiť',
  'controls.confirm_delete.title': 'Potvrďte odstránenie objektu',
  'controls.dropdown.placeholder': 'Vyberte možnosť',
  'controls.forms.field_required': 'Pole je povinné',
  'controls.forms.number_required': 'Zadajte celé, kladné čislo',
  'controls.forms.any_number_required': 'Zadajte číslo',
  'controls.forms.integer_number_required': 'Zadajte celé čislo',
  'controls.forms.positive_number_required': 'Zadajte kladné čislo',
  'controls.forms.number_missing': 'Aspoň jedna hodnota musí byť zadaná',
  'controls.forms.invalid_email': 'Neplatný e-mail',
  'controls.forms.invalid_date': 'Neplatný e-mail',
  'controls.forms.non_empty_array': 'Zadajte aspoň jednu položku',
  'controls.dropzone.upload': 'Vyberte súbor',
  'controls.capacity_kg': '{capacity} kg',
  'controls.capacity_real_kg': '{capacity} kg (reálna)',
  'controls.formset.add': 'Pridať ďalšiu položku',
  'controls.forms.passwords_mismatch': 'Zadané heslá sa nezhodujú',
  'controls.clear_filter': 'Zmazať filter',
  'controls.no_reason': 'Dôvod neuvedený',
  'controls.back': 'Späť',
  'controls.forms.avi.missing_amount': 'Množstvo je povinné',
  'controls.forms.avi.invalid_amount': 'Neplatné množstvo',
  'controls.forms.avi.missing_weight': 'Hmotnosť je povinná',
  'controls.forms.avi.invalid_weight': 'Neplatná hmotnosť',
  'controls.forms.avi.items.at_least_one': 'Aspoň jedna položka musí byť zadaná',
  'controls.forms.date_range.start_required': 'Počiatočný dátum je požadovaný',
  'controls.forms.date_range.end_required': 'Koncový dátum je požadovaný',
  'controls.wrong_download_form': 'Nesprávne vyplnené údaje',
  'controls.wrong_download_internal_error': 'Chyba pri generované výstupu',
  'controls.wrong_download_other_error': 'Chyba pri generované výstupu',
  'controls.select_category': 'Vyberte kategóriu',
  'controls.save': 'Uložiť',

  'enums.price_item_type.inactive': 'Nerelevantné',
  'enums.price_item_type.weight': 'Váha',
  'enums.price_item_type.amount': 'Kusy',
  'enums.amount_dependency.independent': 'Nezávisí na počte',
  'enums.amount_dependency.dependent': 'Závisí na počte',
  'enums.car_price_list_type.default': 'Štandardný',
  'enums.car_price_list_type.public': 'Verejný',
  'enums.car_price_list_type.private': 'Súkromný',

  'enums.car_price_list_type_dropdown.distance': 'Vzdialenosť',
  'enums.car_price_list_type_dropdown.weight_and_pieces': 'Váha a kusy',

  'enums.request_status.waiting': 'Čakajúca',
  'enums.request_status.accepted': 'Prijatá',
  'enums.request_status.refused': 'Zamietnutá',
  'enums.request_status.processing': 'Nakladanie',
  'enums.request_status.stored': 'V preprave',
  'enums.request_status.finished': 'Dokončená',
  'enums.request_status.weighted': 'Uskladnená',
  'enums.request_status.transhipment': 'Čaká na zváženie',
  'enums.request_status.pricing': 'Naceňuje sa',
  'enums.request_status.finished_error': 'Dokončená s chybou',

  'enums.subject_status.waiting': 'Čakajúci na schválenie',
  'enums.subject_status.approved': 'Schválený',
  'enums.subject_status.refused': 'Zamietnutý',

  'enums.office_kind.customer': 'Odosielateľ',
  'enums.office_kind.carrier': 'Prepravca',
  'enums.office_kind.warehouse': 'Sklad',
  'enums.office_kind.evaluator': 'Spracovateľ',
  'enums.office_kind.administration': 'Samospráva',
  'enums.office_kind.container': 'Kontajner',
  'enums.office_kind.transhipment': 'Prekládka',
  'enums.office_kind.staff': 'Zamestnanec',
  'enums.office_kind.collection_yard': 'Zberný dvor',

  'enums.role.staff_management': 'Manažment',
  'enums.role.staff_view': 'Prehľad',
  'enums.role.staff_logistic': 'Logistika',
  'enums.role.staff_processing': 'Spracovanie',
  'enums.role.staff_collection': 'Zber',
  'enums.role.staff_administrator': 'Administrátor',
  'enums.role.subject_administrator': 'Administrátor subjektu',
  'enums.role.subject_accountant': 'Účtovník subjektu',
  'enums.role.subject_logistic': 'Logistika subjektu',

  'enums.legal_form.sro': 's.r.o.',
  'enums.legal_form.as': 'a.s.',
  'enums.legal_form.ks': 'k.s.',
  'enums.legal_form.vos': 'v.o.s.',
  'enums.legal_form.druzstvo': 'družstvo',
  'enums.legal_form.other': 'iná',

  'enums.vehicle.price_list_items.weight': 'Váha',
  'enums.vehicle.price_list_items.amount': 'Kusy',
  'enums.vehicle.price_list_items.distance': 'Vzdialenosť',

  'enums.vehicle.price_list_kind.default': 'Štandardný',
  'enums.vehicle.price_list_kind.public': 'Verejný',
  'enums.vehicle.price_list_kind.private': 'Súkromný',

  'enums.user_status.waiting': 'Čakajúci',
  'enums.user_status.approved': 'Schválený',
  'enums.user_status.refused': 'Zamietnutý',

  'enums.order_status.waiting': 'Čakajúca',
  'enums.order_status.manual': 'Manuálna',
  'enums.order_status.accepted_envidom': 'Prijatá envidom',
  'enums.order_status.accepted_carrier': 'Prijatá prepravcom',
  'enums.order_status.processing': 'Nakladanie',
  'enums.order_status.stored': 'V preprave',
  'enums.order_status.weighted': 'Uskladnená',
  'enums.order_status.changed_price': 'Zmenená cena',
  'enums.order_status.uninvoiced': 'Nefakturovaná',
  'enums.order_status.finished': 'Dokončená',
  'enums.order_status.finished_error': 'Dokončená s chybou',
  'enums.order_status.refused': 'Zamietnutá',
  'enums.order_status.transhipment': 'Čaká na zváženie',
  'enums.order_status.pricing': 'Naceňuje sa',

  'enums.request_kind.express': 'Expresný',
  'enums.request_kind.without_date': 'Bez dátumu',
  'enums.request_kind.with_date': 'S dátumom',
  'enums.request_kind.collection': 'Mobilný zber',
  'enums.request_kind.private': 'Súkromný',

  'enums.pricing_status.refused': 'Zamietnuté',
  'enums.pricing_status.waiting': 'Čakajúce',
  'enums.pricing_status.changed_price': 'Zmena ceny',
  'enums.pricing_status.uninvoiced': 'Nefaktúrované',
  'enums.pricing_status.finished': 'Dokončené',
  'enums.pricing_status.none': 'Nezadané',

  'enums.item_group_kind.limit': 'Limit',
  'enums.item_group_kind.household': 'Použitie v domácnosti',
  'enums.item_group_kind.non_household': 'Použitie v nedomácnosti',

  'enums.item_category_classification.other': 'Ostatné',
  'enums.item_category_classification.cooling': 'Chladenie',
  'enums.item_category_classification.display': 'Obrazovka',

  'enums.invoice_kind.customer': 'Odosielateľ',
  'enums.invoice_kind.carrier': 'Prepravca',
  'enums.invoice_kind.warehouse': 'Sklad',
  'enums.invoice_kind.evaluator': 'Spracovateľ',
  'enums.invoice_kind.administration': 'Samospráva',

  'enums.invoice_status.waiting': 'Čaká na schválenie',
  'enums.invoice_status.accepted': 'Schválená',
  'enums.invoice_status.refused': 'Zamietnutá',

  'enums.limit_kind.envidom': 'Envidom',
  'enums.limit_kind.governmental': 'Štát',
  'enums.limit_kind.processor': 'Spracovávateľ',

  'response_errors.err_network': 'Chyba sieťového pripojenia',
  'response_errors.err_fetch_title': 'Nepodarilo sa získať údaje',
  'response_errors.err_delete_title': 'Nepodarilo sa odstrániť objekt',
  'response_errors.err_send_title': 'Nepodarilo sa odoslať údaje',
  'response_errors.bad_request': 'Nesprávne alebo nekompletné údaje',
  'response_errors.err_bad_response': 'Interná chyba servera',
  'response_errors.err_bad_request': 'Nesprávne formulovaná požiadavka',
  'response_errors.econnaborted': 'Odpojené',
  'response.errors.404': 'Objekt sa nenašiel',
  'response.errors.422': 'Nekompletné alebo nesprávne údaje',
  'response.errors.500': 'Interná chyba servera',

  'documents.error.status': 'Chyba pri získavaní stavu dokumentov',
  'errors.no_primary_office': 'Používateľ nemá nastavenú primárnu prevádzku',

  'office_kinds.title': 'Typy prevádzok',
  'office_kinds.table.kind': 'Typ',
  'office_kinds.table.name': 'Názov',
  'office_kinds.table.short_name': 'Skratka',
  'office_kinds.table.created_at': 'Dátum vytvorenia',

  'office_kinds.delete.confirm.message': 'Naozaj chcete odstrániť typ prevádzky?',
  'office_kinds.delete.error': 'Pri odstraňovaní typu prevádzky došlo k chybe',

  'office_kinds.form.name': 'Názov',
  'office_kinds.form.short_name': 'Skratka',
  'office_kinds.form.kind': 'Typ prevádzky',
  'office_kinds.form.submit': 'Uložiť',
  'office_kinds.form.create': 'Vytvoriť typ prevádzky',
  'office_kinds.form.update': 'Upraviť typ prevádzky',

  'invoice_detail_popup.title': 'Stiahnutie faktúry',
  'invoice_detail_popup.download_button': 'Stiahnutie faktúry',
  'invoice_detail_popup.table.request': 'Požiadavka',
  'invoice_detail_popup.table.recipient': 'Prijímateľ',
  'invoice_detail_popup.table.location': 'Mesto a adresa',
  'invoice_detail_popup.table.note': 'Poznámka',
  'invoice_detail_popup.table.created_at': 'Vytvorená',
  'invoice_detail_popup.download_error': 'Nepodarilo sa stiahnuť dokument'
};
