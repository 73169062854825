import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  requests: 23
};

const countersSlice = createSlice({
  name: 'counters',
  initialState,
  reducers: {}
});

export default countersSlice.reducer;
