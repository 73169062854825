import { all, fork, takeEvery, takeLatest } from 'redux-saga/effects';
import { fetchDetail, fetchTable, sendData } from '../helpers/sagaHelpers';

function* watchFetchCatalogNumbersTable() {
  yield takeLatest('catalogNumbers/fetchTable', fetchTable);
}

function* watchFetchCatalogNumbersDetail() {
  yield takeLatest('catalogNumbers/fetchDetail', fetchDetail);
}

function* watchSendCatalogNumberDetail() {
  yield takeEvery('catalogNumbers/submitDetail', sendData);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchCatalogNumbersDetail),
    fork(watchSendCatalogNumberDetail),
    fork(watchFetchCatalogNumbersTable)
  ]);
}
