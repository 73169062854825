import { createSlice } from '@reduxjs/toolkit';
import { v4 } from 'uuid';

const initialState = {
  messages: []
};

const displaySlice = createSlice({
  name: 'display',
  initialState,
  reducers: {
    reportMessage: (state, action) => {
      const { message, title, type, handle } = action.payload;
      state.messages.push({
        message: message || '',
        title: title || '',
        type: type || 'info',
        handle: handle || v4()
      });
    },
    hideMessage: (state, action) => {
      if (action.payload.handle) {
        state.messages = state.messages.filter(m => m.handle !== action.payload.handle);
      }
    }
  }
});

export default displaySlice.reducer;
