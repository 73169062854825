import { createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '../../lowLevelUtils';
import invariant from 'invariant';

const initialState = {
  states: [
    'new',
    'waiting_confirm',
    'waiting_acquire',
    'waiting_approval',
    'in_transit',
    'waiting_invoice',
    'waiting_change_approval',
    'closed',
    'finished'
  ],

  itemCategories: null,
  itemCategoriesState: LoadingState.IDLE,
  offices: null,
  officesState: LoadingState.IDLE,
  regions: null,
  regionsState: LoadingState.IDLE,
  postalCodes: null,
  postalCodesState: LoadingState.IDLE,
  groups: null,
  groupsState: LoadingState.IDLE,
  roles: null,
  rolesState: LoadingState.IDLE,
  itemGroups: null,
  itemGroupsState: LoadingState.IDLE,
  filterOfficesCarriers: null,
  filterOfficesCarriersState: LoadingState.IDLE,
  filterOfficesWarehouses: null,
  filterOfficesWarehousesState: LoadingState.IDLE,
  filterOfficesOrigins: null,
  filterOfficesOriginsState: LoadingState.IDLE,
  filterOfficesDestinations: null,
  filterOfficesDestinationsState: LoadingState.IDLE,
  filterOfficesDestinationsAll: null,
  filterOfficesDestinationsAllState: LoadingState.IDLE,
  filterOfficesTransportDestinations: null,
  filterOfficesTransportDestinationsState: LoadingState.IDLE,
  filterOfficesCustomers: null,
  filterOfficesCustomersState: LoadingState.IDLE,
  filterOfficesEvaluators: null,
  filterOfficesEvaluatorsState: LoadingState.IDLE,
  filterOfficesSlnoDestinations: null,
  filterOfficesSlnoDestinationsState: LoadingState.IDLE,
  filterOfficesMine: null,
  filterOfficesMineState: LoadingState.IDLE,
  catalogNumbers: null,
  catalogNumbersState: LoadingState.IDLE,
  filterOfficesTranshipmentReference: null,
  filterOfficesTranshipmentReferenceState: LoadingState.IDLE,
  additionalPricingItems: null,
  additionalPricingItemsState: LoadingState.IDLE,
  legalForms: null,
  legalFormsState: LoadingState.IDLE,
  districts: null,
  districtsState: LoadingState.IDLE,
  administrationDistricts: null,
  administrationDistrictsState: LoadingState.IDLE,
  limitItemGroups: null,
  limitItemGroupsState: LoadingState.IDLE,
  officeKinds: null,
  officeKindsState: LoadingState.IDLE
};

const optionsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {
    setTableLoading: (state, action) => {
      invariant(action.payload.tableName, "Must specify table name for 'options' slice");
      state[`${action.payload.tableName}State`] = action.payload.state;
    },
    setTableData: (state, action) => {
      state[`${action.payload.tableName}State`] =
        action.payload.data !== null ? LoadingState.IDLE : LoadingState.ERROR;
      state[action.payload.tableName] = action.payload.data;
    },
    clearGlobals: state => {
      state.itemCategories = null;
      state.itemCategoriesState = LoadingState.IDLE;
      state.offices = null;
      state.officesState = LoadingState.IDLE;
      state.regions = null;
      state.regionsState = LoadingState.IDLE;
      state.postalCodes = null;
      state.postalCodesState = LoadingState.IDLE;
      state.groups = null;
      state.groupsState = LoadingState.IDLE;
      state.roles = null;
      state.rolesState = LoadingState.IDLE;
      state.itemGroups = null;
      state.itemGroupsState = LoadingState.IDLE;
      state.filterOfficesCarriers = null;
      state.filterOfficesCarriersState = LoadingState.IDLE;
      state.filterOfficesWarehouses = null;
      state.filterOfficesWarehousesState = LoadingState.IDLE;
      state.filterOfficesOrigins = null;
      state.filterOfficesOriginsState = LoadingState.IDLE;
      state.filterOfficesDestinations = null;
      state.filterOfficesOriginsState = LoadingState.IDLE;
      state.filterOfficesEvaluators = null;
      state.filterOfficesEvaluatorsState = LoadingState.IDLE;
      state.filterOfficesCustomers = null;
      state.filterOfficesCustomersState = LoadingState.IDLE;
      state.catalogNumbers = null;
      state.catalogNumbersState = LoadingState.IDLE;
      state.filterOfficesTranshipmentReference = null;
      state.filterOfficesTranshipmentReferenceState = LoadingState.IDLE;
      state.additionalPricingItems = null;
      state.additionalPricingItemsState = LoadingState.IDLE;
      state.legalForms = null;
      state.legalFormsState = LoadingState.IDLE;
      state.districts = null;
      state.districtsState = LoadingState.IDLE;
      state.administrationDistricts = null;
      state.administrationDistrictsState = LoadingState.IDLE;
      state.limitItemGroups = null;
      state.limitItemGroupsState = LoadingState.IDLE;
      state.filterOfficesMine = null;
      state.filterOfficesMineState = LoadingState.IDLE;
      state.officeKinds = null;
      state.officeKindsState = LoadingState.IDLE;
    }
  }
});

export default optionsSlice.reducer;
