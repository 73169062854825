import { all, fork, takeLatest } from 'redux-saga/effects';
import { fetchDetail, fetchTable, sendData } from '../helpers/sagaHelpers';

function* watchFetchTable() {
  yield takeLatest('postalCodes/fetchTable', fetchTable);
}

function* watchFetchDetail() {
  yield takeLatest('postalCodes/fetchDetail', fetchDetail);
}

function* watchSendData() {
  yield takeLatest('postalCodes/submitDetail', sendData);
}

export default function* rootSaga() {
  yield all([fork(watchFetchTable), fork(watchFetchDetail), fork(watchSendData)]);
}
