import Cookies from 'js-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { FORGOT_PASSWORD, LOGIN_USER, LOGOUT_USER, RESET_PASSWORD } from '../contants';

import { loginUserSuccess, loginUserError } from './actions';
import LoginApi from '../../api/login';
import { extractErrorMessage, extractFormErrors } from '../../api/utils';
import { AUTH_TOKEN } from '../../api/constants';
import { GetApi, PostApi } from '../../api/api';
import { loginPath, requestsPath } from '../../routes';
import { getCurrentOffice, setCurrentUser, setDefaultOffice } from '../../helpers/Utils';
import { getMenuPath } from '../../constants/menu';
import { rawTranslate } from '../../lowLevelUtils';

export const setAuthToken = token => {
  if (token) {
    Cookies.set(AUTH_TOKEN, token, { expires: 7 });
  } else {
    Cookies.remove(AUTH_TOKEN);
  }
};

export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) =>
  LoginApi({
    url: 'auth',
    data: { email, password },
    method: 'POST',
    handleResponse: response => response,
    handleError: error => error
  });

const getUserData = async () =>
  GetApi({
    url: 'users/me',
    handleResponse: response => response,
    handleError: error => error
  });

const doLogout = async () =>
  PostApi({
    url: 'logout',
    method: 'DELETE',
    handleResponse: response => response,
    handleError: error => error
  });

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const response = yield call(loginWithEmailPasswordAsync, email, password);
    if (response.status === 201) {
      const { token } = response.data.response;
      yield put(loginUserSuccess(token));
      setAuthToken(token);
      const userResponse = yield call(getUserData);
      setCurrentUser(userResponse.data.response);
      setDefaultOffice(userResponse);
      const currentOffice = getCurrentOffice();
      if (currentOffice) {
        const path = getMenuPath(currentOffice.kind);
        history.push(path);
      } else {
        yield put({
          type: 'display/reportMessage',
          payload: { message: rawTranslate('errors.no_primary_office'), type: 'error' }
        });
      }
    } else {
      const message = extractErrorMessage(response);
      yield put({ type: 'display/reportMessage', payload: { message, type: 'error' } });
      yield put(loginUserError(''));
    }
  } catch (error) {
    const message = extractErrorMessage(error);
    yield put({ type: 'display/reportMessage', payload: { message, type: 'error' } });
    yield put(loginUserError(''));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async history => {
  await doLogout()
    .then(() => {
      setAuthToken(null);
      setCurrentUser(null);
      localStorage.removeItem('currentOffice');
      history.push(loginPath);
    })
    .catch(() => {
      setAuthToken(null);
      setCurrentUser(null);
      localStorage.removeItem('currentOffice');
      history.push(loginPath);
    });
};

function* logout({ payload }) {
  const { history } = payload;
  yield call(logoutAsync, history);
  window.location.reload();
}

const doForgotPassword = async email =>
  PostApi({
    url: 'password/recovery',
    method: 'POST',
    data: { email },
    handleResponse: response => response,
    handleError: error => error
  });

const forgotPasswordAsync = async action => {
  const { email, onFinish } = action.payload;
  await doForgotPassword(email)
    .then(() => {
      onFinish(true);
    })
    .catch(() => {
      onFinish(false);
    });
};

const doResetPassword = async payload => {
  const { password, recovery_code } = payload;
  return PostApi({
    url: 'password/change',
    method: 'POST',
    data: { password, recovery_code },
    handleResponse: response => response,
    handleError: error => error
  });
};

const resetPasswordAsync = async action => {
  const { onFinish } = action.payload;
  await doResetPassword(action.payload)
    .then(response => {
      const status = _.get(response, 'response.status', 0);
      if (status < 300) {
        onFinish(true, null);
      } else {
        const ve = _.get(response, 'response.data.validation_errors', []);
        const fe = extractFormErrors(ve);
        const rce = _.get(fe, 'fields.recovery_code', null);
        if (rce) {
          fe.nonField.push(rce[0]);
        }
        onFinish(false, fe, status);
      }
    })
    .catch(response => {
      onFinish(false, { nonField: 'Nastala chyba pri zmene hesla' });
    });
};

function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPasswordAsync);
}

function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPasswordAsync);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchForgotPassword),
    fork(watchResetPassword)
  ]);
}
