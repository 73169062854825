import { takeEvery, all, fork } from 'redux-saga/effects';
import { sendData } from '../helpers/sagaHelpers';

function* watchChangePassword() {
  yield takeEvery('profile/changePassword', sendData);
}

export default function* rootSaga() {
  yield all([fork(watchChangePassword)]);
}
