import { takeLatest, all, fork } from 'redux-saga/effects';
import { fetchDetail, fetchTable, sendData } from '../helpers/sagaHelpers';

function* watchFetchInvoices() {
  yield takeLatest('invoices/fetchTable', fetchTable);
}

function* watchFetchInvoicedObjects() {
  yield takeLatest('invoicedObjects/fetchTable', fetchTable);
}

function* watchSubmitInvoice() {
  yield takeLatest('invoices/submitDetail', sendData);
}

function* watchFetchDetail() {
  yield takeLatest('invoices/fetchDetail', fetchDetail);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchInvoices),
    fork(watchFetchInvoicedObjects),
    fork(watchSubmitInvoice),
    fork(watchFetchDetail)
  ]);
}
