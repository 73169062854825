import {
  createEmptySliceBase,
  injectDetailSlice,
  injectTableSlice,
  toReducer
} from '../sliceFactory';

const createGlobalLimitsReducer = ({ name, initialSort }) => {
  const base = createEmptySliceBase({ name });
  const withTable = injectTableSlice({ initialSort, sliceBase: base, limit: 100 });
  const withDetail = injectDetailSlice({ sliceBase: withTable });
  return toReducer(withDetail);
};

export { createGlobalLimitsReducer };
