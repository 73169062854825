/* eslint-disable global-require */
// eslint-disable-next-line
import * as Sentry from "@sentry/browser";
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-image-lightbox/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import { isMultiColorActive, defaultColor, isDarkSwitchActive } from './constants/defaultValues';
import { getCurrentColor, setCurrentColor } from './helpers/Utils';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://097a52be4c9d45cd8e995c39b8434fe3@sentry.backbone.sk/24'
  });
}

const color = isMultiColorActive || isDarkSwitchActive ? getCurrentColor() : defaultColor;
setCurrentColor(color);

const render = () => {
  import(`./assets/css/sass/themes/gogo.${color}.scss`).then(() => {
    require('./AppRenderer');
  });
};
render();
