import {
  createEmptySliceBase,
  injectDetailSlice,
  injectTableSlice,
  toReducer
} from '../sliceFactory';

const createPostalCodesReducer = () => {
  const sliceBase = createEmptySliceBase({ name: 'postalCodes' });
  const withTable = injectTableSlice({ initialSort: 'code', sliceBase });
  const withDetail = injectDetailSlice({ sliceBase: withTable });
  return toReducer(withDetail);
};

export default createPostalCodesReducer;
