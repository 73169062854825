import { all, fork, takeLatest } from 'redux-saga/effects';
import { fetchAuxTable, fetchTable, sendData } from '../helpers/sagaHelpers';

function* waitFetchPendingRequests() {
  yield takeLatest('waitingTransports/fetchTable', fetchTable);
}

function* waitFetchAvailableDestinations() {
  yield takeLatest('waitingTransports/fetchAvailableDestinations', fetchAuxTable);
}

function* waitQueryAvailableCarriers() {
  yield takeLatest('waitingTransports/queryAvailableCarriers', sendData);
}

function* waitQueryAvailableOrders() {
  yield takeLatest('waitingTransports/queryAvailableOrders', fetchTable);
}

function* waitSubmitOrder() {
  yield takeLatest('waitingTransports/submitOrder', sendData);
}

export default function* rootSaga() {
  yield all([
    fork(waitFetchPendingRequests),
    fork(waitFetchAvailableDestinations),
    fork(waitQueryAvailableCarriers),
    fork(waitSubmitOrder),
    fork(waitQueryAvailableOrders)
  ]);
}
