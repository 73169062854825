import { adminRoot } from './defaultValues';
import {
  homePath,
  requestsPath,
  partnersPath,
  storagesPath,
  transportPath,
  listsPath,
  reportsPath,
  officeSwitchPath,
  invoicesPath
} from '../routes';
import { OfficeKind } from '../globalEnums';

const menuItems = [
  {
    id: 'requests',
    titleTranslateKey: 'menu.requests',
    icon: 'menu-orders',
    counter: 'requests',
    to: `${adminRoot}${requestsPath}`,
    // roles: [UserRole.Admin, UserRole.Editor],
    hidden: officeKind =>
      [
        OfficeKind.CARRIER,
        OfficeKind.TRANSHIPMENT,
        OfficeKind.EVALUATOR,
        OfficeKind.WAREHOUSE
      ].includes(officeKind)
  },
  {
    id: 'transport',
    titleTranslateKey: 'menu.transport',
    icon: 'menu-transport',
    to: `${adminRoot}${transportPath}`,
    hidden: officeKind =>
      [
        OfficeKind.CUSTOMER,
        OfficeKind.TRANSHIPMENT,
        OfficeKind.EVALUATOR,
        OfficeKind.WAREHOUSE
      ].includes(officeKind)
  },
  {
    id: 'storages',
    titleTranslateKey: 'menu.storages',
    icon: 'menu-storages',
    to: `${adminRoot}${storagesPath}`,
    separator: true,
    hidden: officeKind => [OfficeKind.CUSTOMER, OfficeKind.CARRIER].includes(officeKind)
  },
  {
    id: 'reports',
    titleTranslateKey: 'menu.reports',
    icon: 'menu-forms',
    to: `${adminRoot}${reportsPath}`,
    hidden: (officeKind, currentUser, currentOfficeIsSlno) =>
      officeKind === OfficeKind.TRANSHIPMENT ||
      (officeKind === OfficeKind.CUSTOMER && !currentOfficeIsSlno)
  },
  {
    id: 'invoices',
    titleTranslateKey: 'menu.invoices',
    icon: 'menu-invoices',
    to: `${adminRoot}${invoicesPath}`,
    separator: true,
    disabled: false
  },
  {
    id: 'organizations',
    titleTranslateKey: 'menu.organizations',
    icon: 'menu-organizations',
    to: `${adminRoot}${partnersPath}`
  },
  {
    id: 'lists',
    titleTranslateKey: 'menu.lists',
    icon: 'menu-settings',
    to: `${adminRoot}${listsPath}`,
    separator: true,
    hidden: (officeKind, currentUser) => !currentUser?.is_staff
  },
  {
    id: 'office-switch',
    titleTranslateKey: 'menu.office-switch',
    icon: 'menu-settings',
    to: `${adminRoot}${officeSwitchPath}`,
    separator: true,
    hidden: (officeKind, currentUser) => currentUser?.is_staff,
    disabled: (officeKind, currentUser) => currentUser?.user_office_groups?.length <= 1
  }
];

const getMenuPath = officeKind => {
  if (officeKind === OfficeKind.CARRIER) {
    return `${adminRoot}${transportPath}`;
  }
  if ([OfficeKind.WAREHOUSE, OfficeKind.TRANSHIPMENT].includes(officeKind)) {
    return `${adminRoot}${storagesPath}`;
  }
  return `${adminRoot}${requestsPath}`;
};

export { menuItems, getMenuPath };
