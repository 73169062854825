import { all, fork, takeLatest, takeLeading } from 'redux-saga/effects';
import { sendData, fetchTable, fetchDetail } from '../helpers/sagaHelpers';

function* watchFetchRequestsTable() {
  yield takeLatest('autonomousRequests/fetchTable', fetchTable);
}

export default function* rootSaga() {
  yield all([fork(watchFetchRequestsTable)]);
}
