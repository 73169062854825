import {
  createEmptySliceBase,
  injectDetailSlice,
  injectTableSlice,
  toReducer
} from '../sliceFactory';
import * as uuid from 'uuid';

const createPlannedTransportsReducer = name => {
  const sliceBase = createEmptySliceBase({ name });
  const withTable = injectTableSlice({ sliceBase });
  const withDetail = injectDetailSlice({ sliceBase: withTable });
  withDetail.initialState.record = null;
  withDetail.initialState.refreshDetailToken = '';

  withDetail.reducers.setRecord = (state, action) => {
    console.log('setRecord', action);
  };

  withDetail.initialState.listDirty = false;
  withDetail.reducers.setListDirty = (state, action) => {
    state.listDirty = action.payload;
  };

  withDetail.reducers.refreshDetail = state => {
    state.refreshDetailToken = uuid.v4();
  };
  // eslint-disable-next-line prettier/prettier

  withDetail.initialState.submittingPlan = true;
  withDetail.reducers.setSubmittingPlan = (state, action) => {
    state.submittingPlan = action.payload;
  };

  return toReducer(withDetail);
};

const createPlannedTransportsRecordReducer = name => {
  const sliceBase = createEmptySliceBase({ name });
  const withDetail = injectDetailSlice({ sliceBase });
  return toReducer(withDetail);
};

export { createPlannedTransportsReducer, createPlannedTransportsRecordReducer };
