import { all, fork, takeLatest } from 'redux-saga/effects';
import {
  createEmptySliceBase,
  injectDetailSlice,
  injectTableSlice,
  toReducer
} from '../sliceFactory';
import { fetchDetail, fetchTable, sendData } from '../helpers/sagaHelpers';

const sliceName = 'subjectAdditionalPricing';

const createSubjectAdditionalPricingSlice = () => {
  const base = createEmptySliceBase({ name: sliceName });
  const withTable = injectTableSlice({ sliceBase: base, limit: 10000 });
  const withDetail = injectDetailSlice({ sliceBase: withTable });
  return toReducer(withDetail);
};

function* watchFetchAdditionalPricingTable() {
  yield takeLatest(`${sliceName}/fetchTable`, fetchTable);
}

function* watchFetchAdditionalPricingDetail() {
  yield takeLatest(`${sliceName}/fetchDetail`, fetchDetail);
}

function* watchSubmitAdditionalPricingDetail() {
  yield takeLatest(`${sliceName}/submitDetail`, sendData);
}

function* subjectAdditionalPricingSaga() {
  yield all([
    fork(watchFetchAdditionalPricingTable),
    fork(watchFetchAdditionalPricingDetail),
    fork(watchSubmitAdditionalPricingDetail)
  ]);
}

export { createSubjectAdditionalPricingSlice, subjectAdditionalPricingSaga };
