import { all, fork, takeLatest, takeEvery } from 'redux-saga/effects';
import { sendData } from '../helpers/sagaHelpers';

function* watchFetchDocument() {
  yield takeEvery('documents/fetchDocument', sendData);
}

function* watchSubmitDocument() {
  yield takeLatest('documents/submit', sendData);
}

export default function* rootSaga() {
  yield all([fork(watchFetchDocument), fork(watchSubmitDocument)]);
}
