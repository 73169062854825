import { all, fork, takeLatest, takeEvery } from 'redux-saga/effects';
import { fetchDetail, fetchTable, sendData } from '../helpers/sagaHelpers';

function* watchFetchTableIncoming() {
  yield takeLatest('warehouseIncoming/fetchTable', fetchTable);
}

function* watchFetchTablePending() {
  yield takeLatest('warehousePending/fetchTable', fetchTable);
}

function* watchFetchTableOutgoing() {
  yield takeLatest('warehouseOutgoing/fetchTable', fetchTable);
}

function* watchFetchIncomingDetail() {
  yield takeLatest('warehouseIncoming/fetchDetail', fetchDetail);
}

function* watchSubmitIncomingDetail() {
  yield takeEvery('warehouseIncoming/submitDetail', sendData);
}

function* watchFetchOutgoingDetail() {
  yield takeLatest('warehouseOutgoing/fetchDetail', fetchDetail);
}

function* watchSubmitOutgoingDetail() {
  yield takeEvery('warehouseOutgoing/submitDetail', sendData);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchTableIncoming),
    fork(watchFetchTableOutgoing),
    fork(watchFetchTablePending),
    fork(watchFetchIncomingDetail),
    fork(watchSubmitIncomingDetail),
    fork(watchFetchOutgoingDetail),
    fork(watchSubmitOutgoingDetail)
  ]);
}
