import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import { AUTH_TOKEN } from './constants';
import { loginPath } from '../routes';
import { setCurrentUser } from '../helpers/Utils';

const logout = () => {
  Cookies.remove(AUTH_TOKEN);
  localStorage.removeItem('currentOffice');
  setCurrentUser();
  window.location.replace(loginPath);
};

const config = {
  privateKey: process.env.REACT_APP_SECRET,
  publicKey: process.env.REACT_APP_API_KEY,
  rootUrl: process.env.REACT_APP_ROOT_URL
};

const hmacAdapter = axios => {
  return request => {
    const fullUrl =
      request.url.startsWith('http://') || request.url.startsWith('https://')
        ? request.url
        : request.baseURL + request.url;

    let url = fullUrl.replace(config.rootUrl, '');

    const n = url.indexOf('?');
    url = url.substring(0, n !== -1 ? n : url.length);

    const payload = request.data !== undefined ? request.data : '';
    const message = [payload, url].join(':');
    request.headers['X-Signature'] = CryptoJS.HmacSHA256(message, config.privateKey).toString();

    return axios.defaults.adapter.call(this, request);
  };
};

export { logout, hmacAdapter };
