import { all, fork, takeLatest } from 'redux-saga/effects';
import { fetchDetail, fetchTable, sendData } from '../helpers/sagaHelpers';

function* watchFetchPlannedTransports() {
  yield takeLatest('plannedTransports/fetchTable', fetchTable);
}

function* watchFetchOrderDetail() {
  yield takeLatest('plannedTransports/fetchDetail', fetchDetail);
}

function* watchProcessOrder() {
  yield takeLatest('plannedTransports/processOrder', sendData);
}

function* watchFetchRecords() {
  yield takeLatest('plannedTransports/fetchRecord', fetchTable);
}

function* watchSubmitWarehouseLog() {
  yield takeLatest('plannedTransports/submitWarehouseLog', sendData);
}

function* watchSubmitPlan() {
  yield takeLatest('plannedTransports/submitPlan', sendData);
}

function* watchSubmitProcessOrder() {
  yield takeLatest('plannedTransports/setOrderProcessing', sendData);
}

// toto je iny slice, ale nechavam to v jednej sage
function* watchFetchRecordDetail() {
  yield takeLatest('plannedTransportsRecord/fetchDetail', fetchDetail);
}

function* watchSubmitRecordDetail() {
  yield takeLatest('plannedTransportsRecord/submitDetail', sendData);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchPlannedTransports),
    fork(watchFetchOrderDetail),
    fork(watchProcessOrder),
    fork(watchFetchRecords),
    fork(watchSubmitWarehouseLog),
    fork(watchSubmitPlan),
    fork(watchSubmitProcessOrder),
    fork(watchFetchRecordDetail),
    fork(watchSubmitRecordDetail)
  ]);
}
