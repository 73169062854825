const SubjectStatus = {
  WAITING: 'waiting',
  APPROVED: 'approved',
  REFUSED: 'refused'
};

const OfficeKind = {
  CUSTOMER: 'customer',
  CARRIER: 'carrier',
  WAREHOUSE: 'warehouse',
  EVALUATOR: 'evaluator',
  ADMINISTRATION: 'administration',
  TRANSHIPMENT: 'transhipment',
  STAFF: 'staff'
};

const OfficeKindList = [
  OfficeKind.CUSTOMER,
  OfficeKind.CARRIER,
  OfficeKind.WAREHOUSE,
  OfficeKind.EVALUATOR,
  OfficeKind.ADMINISTRATION,
  OfficeKind.TRANSHIPMENT,
  OfficeKind.STAFF
];

const TRANSPORT_ENDPOINTS = [OfficeKind.WAREHOUSE, OfficeKind.EVALUATOR, OfficeKind.TRANSHIPMENT];

const getOfficeKindTranslations = messages => {
  const result = {};
  OfficeKindList.forEach(v => {
    result[v] = messages[`enums.office_kind.${v}`];
  });
  return result;
};

const RequestStatus = {
  WAITING: 'waiting',
  ACCEPTED: 'accepted',
  REFUSED: 'refused',
  PROCESSING: 'processing',
  STORED: 'stored',
  WEIGHTED: 'weighted',
  FINISHED: 'finished',
  TRANSHIPMENT: 'transhipment',
  PRICING: 'pricing'
};

const allRequestStates = [
  RequestStatus.WAITING,
  RequestStatus.ACCEPTED,
  RequestStatus.REFUSED,
  RequestStatus.PROCESSING,
  RequestStatus.STORED,
  RequestStatus.WEIGHTED,
  RequestStatus.FINISHED,
  RequestStatus.TRANSHIPMENT
];

const OrderStatus = {
  WAITING: 'waiting',
  MANUAL: 'manual',
  ACCEPTED_ENVIDOM: 'accepted_envidom',
  ACCEPTED_CARRIER: 'accepted_carrier',
  PROCESSING: 'processing',
  STORED: 'stored',
  WEIGHTED: 'weighted',
  CHANGED_PRICE: 'changed_price',
  UNINVOICED: 'uninvoiced',
  FINISHED: 'finished',
  FINISHED_ERROR: 'finished_error',
  REFUSED: 'refused',
  TRANSHIPMENT: 'transhipment',
  PRICING: 'pricing'
};

const OrderStatesList = [
  OrderStatus.WAITING,
  OrderStatus.MANUAL,
  OrderStatus.ACCEPTED_ENVIDOM,
  OrderStatus.ACCEPTED_CARRIER,
  OrderStatus.PROCESSING,
  OrderStatus.STORED,
  OrderStatus.WEIGHTED,
  OrderStatus.CHANGED_PRICE,
  OrderStatus.UNINVOICED,
  OrderStatus.FINISHED,
  OrderStatus.FINISHED_ERROR,
  OrderStatus.REFUSED,
  OrderStatus.TRANSHIPMENT
];

const COLOR_OK = '#28914c';
const COLOR_WAITING = '#d6be08';
const COLOR_FAILURE = '#dd4747';
const COLOR_NONE = '#6c6c6c';

const orderStateToColor = {
  waiting: COLOR_WAITING,
  manual: COLOR_WAITING,
  accepted_envidom: COLOR_OK,
  accepted_carrier: COLOR_OK,
  processing: COLOR_OK,
  stored: COLOR_OK,
  weighted: COLOR_OK,
  changed_price: COLOR_WAITING,
  uninvoiced: COLOR_WAITING,
  finished: COLOR_OK,
  finished_error: COLOR_FAILURE,
  refused: COLOR_FAILURE,
  transhipment: COLOR_WAITING
};

const RequestKind = {
  EXPRESS: 'express',
  WITHOUT_DATE: 'without_date',
  WITH_DATE: 'with_date',
  COLLECTION: 'collection',
  PRIVATE: 'private'
};

const RequestKinds = [
  RequestKind.EXPRESS,
  RequestKind.WITHOUT_DATE,
  RequestKind.WITH_DATE,
  RequestKind.COLLECTION,
  RequestKind.PRIVATE
];

const PricingStatus = {
  REFUSED: 'refused',
  WAITING: 'waiting',
  CHANGED_PRICE: 'changed_price',
  UNINVOICED: 'uninvoiced',
  FINISHED: 'finished',
  NONE: 'none'
};

const PricingStates = [
  PricingStatus.REFUSED,
  PricingStatus.WAITING,
  PricingStatus.CHANGED_PRICE,
  PricingStatus.UNINVOICED,
  PricingStatus.FINISHED
];

const pricingStatusColorMap = {
  [PricingStatus.REFUSED]: COLOR_FAILURE,
  [PricingStatus.WAITING]: COLOR_WAITING,
  [PricingStatus.CHANGED_PRICE]: COLOR_WAITING,
  [PricingStatus.UNINVOICED]: COLOR_OK,
  [PricingStatus.FINISHED]: COLOR_OK
};

const ItemGroupKind = {
  LIMIT: 'limit',
  HOUSEHOLD: 'household',
  NON_HOUSEHOLD: 'non_household'
};

const InvoiceKind = {
  CUSTOMER: 'customer',
  CARRIER: 'carrier',
  WAREHOUSE: 'warehouse',
  EVALUATOR: 'evaluator',
  ADMINISTRATION: 'administration'
};

const ItemGroupKinds = [ItemGroupKind.LIMIT, ItemGroupKind.HOUSEHOLD, ItemGroupKind.NON_HOUSEHOLD];
const InvoiceKinds = [
  InvoiceKind.CUSTOMER,
  InvoiceKind.CARRIER,
  InvoiceKind.WAREHOUSE,
  InvoiceKind.EVALUATOR,
  InvoiceKind.ADMINISTRATION
];

const InvoiceStatus = {
  WAITING: 'waiting',
  ACCEPTED: 'accepted',
  REFUSED: 'refused'
};

const LimitKind = {
  GOVERNMENTAL: 'governmental',
  ENVIDOM: 'envidom',
  PROCESSOR: 'processor'
};
const LimitKinds = [LimitKind.GOVERNMENTAL, LimitKind.ENVIDOM, LimitKind.PROCESSOR];

const InvoiceStates = [InvoiceStatus.WAITING, InvoiceStatus.ACCEPTED, InvoiceStatus.REFUSED];

const ItemCategoryClassification = {
  OTHER: 'other',
  COOLING: 'cooling',
  DISPLAY: 'display'
};

const BonusName = {
  A: 'A',
  B: 'B',
  C: 'C'
};

const ItemCategoryClassifications = [
  ItemCategoryClassification.OTHER,
  ItemCategoryClassification.COOLING,
  ItemCategoryClassification.DISPLAY
];

const UserStatus = {
  WAITING: 'waiting',
  APPROVED: 'approved',
  REFUSED: 'refused'
};

const USER_STATES = [UserStatus.WAITING, UserStatus.APPROVED, UserStatus.REFUSED];

export {
  SubjectStatus,
  OfficeKind,
  OfficeKindList,
  getOfficeKindTranslations,
  RequestStatus,
  OrderStatus,
  OrderStatesList,
  RequestKind,
  RequestKinds,
  orderStateToColor,
  PricingStatus,
  PricingStates,
  allRequestStates,
  TRANSPORT_ENDPOINTS,
  ItemGroupKind,
  ItemGroupKinds,
  InvoiceKind,
  InvoiceKinds,
  InvoiceStatus,
  InvoiceStates,
  COLOR_OK,
  COLOR_WAITING,
  COLOR_FAILURE,
  COLOR_NONE,
  LimitKind,
  LimitKinds,
  pricingStatusColorMap,
  ItemCategoryClassification,
  ItemCategoryClassifications,
  BonusName,
  UserStatus,
  USER_STATES
};
