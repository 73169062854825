import {
  createEmptySliceBase,
  injectDetailSlice,
  injectTableSlice,
  injectTabSlice,
  toReducer
} from '../sliceFactory';

const createListsReducer = () => {
  const sliceBase = createEmptySliceBase({ name: 'lists' });
  const withTabs = injectTabSlice({ sliceBase });
  return toReducer(withTabs);
};

const createItemCategoriesReducer = ({ name, initialSort }) => {
  const sliceBase = createEmptySliceBase({ name });
  const withTable = injectTableSlice({ initialSort, sliceBase, limit: 100 });
  const withDetail = injectDetailSlice({ sliceBase: withTable });
  return toReducer(withDetail);
};

const createItemGroupsReducer = ({ name, initialSort }) => {
  const sliceBase = createEmptySliceBase({ name });
  const withTable = injectTableSlice({ initialSort, sliceBase, limit: 100 });
  const withDetail = injectDetailSlice({ sliceBase: withTable });
  return toReducer(withDetail);
};

const createBonusPriceListsReducer = ({ name, initialSort }) => {
  const sliceBase = createEmptySliceBase({ name });
  const withTable = injectTableSlice({ initialSort, sliceBase, limit: 10 });
  const withDetail = injectDetailSlice({ sliceBase: withTable });
  return toReducer(withDetail);
};

export {
  createListsReducer,
  createItemCategoriesReducer,
  createItemGroupsReducer,
  createBonusPriceListsReducer
};
