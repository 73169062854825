import { all, fork, takeLatest, takeLeading } from 'redux-saga/effects';
import { fetchDetail, fetchTable, sendData } from '../helpers/sagaHelpers';

function* watchFetchItemCategoriesTable() {
  yield takeLatest('itemCategories/fetchTable', fetchTable);
}

function* watchFetchItemCategoryDetail() {
  yield takeLatest('itemCategories/fetchDetail', fetchDetail);
}

function* watchSubmitItemCategoryDetail() {
  yield takeLeading('itemCategories/submitDetail', sendData);
}

function* watchFetchItemGroupsTable() {
  yield takeLatest('itemGroups/fetchTable', fetchTable);
}

function* watchFetchItemGroupDetail() {
  yield takeLatest('itemGroups/fetchDetail', fetchDetail);
}

function* watchSubmitItemGroupDetail() {
  yield takeLeading('itemGroups/submitDetail', sendData);
}

function* watchFetchLegalFormTable() {
  yield takeLatest('legalForms/fetchTable', fetchTable);
}

function* watchFetchLegalFormDetail() {
  yield takeLatest('legalForms/fetchDetail', fetchDetail);
}

function* watchSubmitLegalFormDetail() {
  yield takeLeading('legalForms/submitDetail', sendData);
}

function* watchFetchBonusPriceListsTable() {
  yield takeLatest('bonusPriceLists/fetchTable', fetchTable);
}

function* watchFetchBonusPriceListsDetail() {
  yield takeLatest('bonusPriceLists/fetchDetail', fetchDetail);
}

function* watchSubmitBonusPriceListDetail() {
  yield takeLatest('bonusPriceLists/submitDetail', sendData);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchItemCategoriesTable),
    fork(watchFetchItemCategoryDetail),
    fork(watchSubmitItemCategoryDetail),
    fork(watchFetchItemGroupsTable),
    fork(watchFetchItemGroupDetail),
    fork(watchSubmitItemGroupDetail),
    fork(watchFetchLegalFormTable),
    fork(watchFetchLegalFormDetail),
    fork(watchSubmitLegalFormDetail),
    fork(watchFetchBonusPriceListsTable),
    fork(watchFetchBonusPriceListsDetail),
    fork(watchSubmitBonusPriceListDetail)
  ]);
}
