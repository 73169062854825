import invariant from 'invariant';
import * as uuid from 'uuid';
import { createEmptySliceBase, toReducer } from '../sliceFactory';
import { LoadingState } from '../../lowLevelUtils';

const createUserRolesReducer = () => {
  const base = createEmptySliceBase({ name: 'userRoles' });
  base.initialState = {
    ...base.initialState,
    offices: null,
    officesState: LoadingState.IDLE,
    groups: null,
    groupsState: LoadingState.IDLE,
    data: null,
    loading: false,
    page: 1,
    limit: 100,
    meta: {},
    filter: {},
    sort: null,
    token: uuid.v4()
  };
  base.reducers = {
    ...base.reducers,
    fetchTable: state => {
      state.loading = true;
    },
    setTableData: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.meta = action.payload.meta;
    },
    setTableLoading: (state, action) => {
      if (action.payload === true || action.payload === false) {
        state.loading = action.payload;
      } else {
        invariant(action.payload.tableName, "Must specify table name for 'options' slice");
        state[`${action.payload.tableName}State`] = action.payload.state;
      }
    },
    refreshTable: state => {
      state.token = uuid.v4();
    }
  };
  return toReducer(base);
};

export default createUserRolesReducer;
