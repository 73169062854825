import { configureStore } from '@reduxjs/toolkit';

// import { applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import settingsReducer from './settings/reducer';
import displayReducer from './display/reducer';
import authUser from './auth/reducer';
import countersReducer from './counters/reducer';
import createRequestsReducer from './requests/reducer';
import createSubjectsReducer from './subjects/reducer';
import partnersReducer from './partners/reducer';
import { createTransportReducer, createWaitingTransportsReducer } from './transport/reducers';
import optionsReducer from './globals/options';
import storagesReducer from './storages/reducer';
import sagas from './sagas';
import { createTableDetailSlice, createTableSlice } from './sliceFactory';
import createOfficesReducer from './offices/reducer';
import createVehiclesReducer from './vehicles/reducer';
import createOfficePriceListsReducer from './officePriceLists/reducer';
import { createLogger } from 'redux-logger';
import createCarPriceListsReducer from './carPriceLists/reducer';
import createUserManagementReducer from './userManagement/reducer';
import createUserRolesReducer from './userRoles/reducer';
import {
  createListsReducer,
  createItemCategoriesReducer,
  createItemGroupsReducer,
  createBonusPriceListsReducer
} from './lists/reducer';
import createPostalCodesReducer from './postalCodes/reducer';
import createRegionsReducer from './regions/reducer';
import {
  createPlannedTransportsReducer,
  createPlannedTransportsRecordReducer
} from './plannedTransports/reducers';
import createDocumentsReducer from './documents/reducer';
import createFinishedTransportsReducer from './finishedTransports/reducer';
import { createGlobalLimitsReducer } from './limits/reducer';
import reportsReducer from './reports/reducer';
import createSLNOSlice from './slnoReports/reducer';
import createInvoicesReducer from './invoices/reducer';
import createProfileReducer from './profile/reducer';
import { createSubjectAdditionalPricingSlice } from './subjects/subjectAdditionalPricing';

const ENABLE_LOGGER = false;

const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [sagaMiddleware];

  const enableLogger =
    process.env.ENV !== 'production' && process.env.NODE_ENV !== 'production' && ENABLE_LOGGER;
  if (enableLogger) {
    const logger = createLogger({
      timestamp: false,
      collapsed: true,
      duration: true
      // diff: true // major performance issues
    });
    middlewares.push(logger);
  }

  const store = configureStore({
    reducer: {
      settings: settingsReducer,
      display: displayReducer,
      authUser: authUser,
      counters: countersReducer,
      requests: createRequestsReducer(),
      autonomousRequests: createTableSlice({
        name: 'autonomousRequests',
        initialSort: '-created_at'
      }),
      finishedRequests: createTableSlice({
        name: 'finishedRequests',
        initialSort: '-created_at'
      }),
      transport: createTransportReducer('transport'),
      waitingTransports: createWaitingTransportsReducer('waitingTransports'),
      plannedTransports: createPlannedTransportsReducer('plannedTransports'),
      transportDestinations: createTableSlice({
        name: 'transportDestinations'
      }),
      plannedTransportsRecord: createPlannedTransportsRecordReducer('plannedTransportsRecord'),
      pendingOrders: createTableSlice({ name: 'pendingOrders', initialSort: '-created_at' }),
      options: optionsReducer,
      partners: partnersReducer,
      storages: storagesReducer,
      subjects: createSubjectsReducer(),
      offices: createOfficesReducer(),
      vehicles: createVehiclesReducer(),
      officePriceLists: createOfficePriceListsReducer(),
      vehiclePriceLists: createCarPriceListsReducer(),
      userManagement: createUserManagementReducer(),
      userRoles: createUserRolesReducer(),
      lists: createListsReducer(),
      postalCodes: createPostalCodesReducer(),
      regions: createRegionsReducer(),
      documents: createDocumentsReducer(),
      finishedTransports: createFinishedTransportsReducer('finishedTransports'),
      warehouseIncoming: createTableDetailSlice({
        name: 'warehouseIncoming',
        initialSort: '-created_at'
      }),
      warehousePending: createTableSlice({ name: 'warehousePending', initialSort: '-created_at' }),
      warehouseOutgoing: createTableDetailSlice({
        name: 'warehouseOutgoing',
        initialSort: '-created_at'
      }),
      itemCategories: createItemCategoriesReducer({
        name: 'itemCategories',
        initialSort: 'sequence'
      }),
      itemGroups: createItemGroupsReducer({ name: 'itemGroups', initialSort: 'sequence' }),
      limits: createGlobalLimitsReducer({ name: 'limits', initialSort: 'name' }),
      limitsEnvidom: createTableSlice({ name: 'limitsEnvidom', initialSort: 'name' }),
      limitsGovernmental: createTableSlice({ name: 'limitsGovernmental', initialSort: 'name' }),
      catalogNumbers: createTableDetailSlice({ name: 'catalogNumbers', initialSort: 'sequence' }),
      officeLimits: createGlobalLimitsReducer({ name: 'officeLimits', initialSort: 'name' }),
      reports: reportsReducer,
      slnoReports: createSLNOSlice(),
      legalForms: createTableDetailSlice({ name: 'legalForms', initialSort: 'name' }),
      invoices: createInvoicesReducer({ name: 'invoices' }),
      invoicedObjects: createTableSlice({
        name: 'invoicedObjects',
        initialSort: 'created_at',
        limit: 1000
      }),
      profile: createProfileReducer(),
      officeKinds: createTableDetailSlice({ name: 'officeKinds', initialSort: 'name' }),
      subjectAdditionalPricing: createSubjectAdditionalPricingSlice(),
      bonusPriceLists: createBonusPriceListsReducer({
        name: 'bonusPriceLists',
        initialSort: 'name'
      })
    },
    // middleware: compose(applyMiddleware(...middlewares))
    middleware: middlewares
  });
  sagaMiddleware.run(sagas);
  return store;
};

export default makeStore;
