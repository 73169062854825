import { all, fork, takeLatest } from 'redux-saga/effects';
import { fetchDetail, sendData, deleteObject } from '../helpers/sagaHelpers';

function* watchFetchVehicleDetail() {
  yield takeLatest('vehicles/fetchDetail', fetchDetail);
}

function* watchSubmitVehicleDetail() {
  yield takeLatest('vehicles/submitVehicle', sendData);
}

function* watchDeleteVehicle() {
  yield takeLatest('vehicles/deleteVehicle', deleteObject);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchVehicleDetail),
    fork(watchSubmitVehicleDetail),
    fork(watchDeleteVehicle)
  ]);
}
