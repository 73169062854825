import { v4 as uuidv4 } from 'uuid';
import { createSlice } from '@reduxjs/toolkit';
import invariant from 'invariant';
import { LoadingState } from '../lowLevelUtils';
import { capitalizeFirstLetter } from '../formatting';

const createTableSlice = ({ name, initialSort, limit = 10 }) => {
  const sliceBase = { name, initialState: {}, reducers: {} };
  const withTable = injectTableSlice({ initialSort, sliceBase, limit });
  return createSlice(withTable).reducer;
};

const createEmptySliceBase = ({ name }) => {
  return { name, initialState: {}, reducers: {} };
};

const toReducer = sliceDeclaration => {
  return createSlice(sliceDeclaration).reducer;
};

const injectTableSlice = ({ initialSort = '-created_at', sliceBase, limit = 10 }) => {
  invariant(sliceBase, 'sliceBase not specified');
  return {
    name: sliceBase.name,
    initialState: {
      ...sliceBase.initialState,
      loading: false,
      data: null,
      filter: {},
      sort: initialSort,
      page: 1,
      limit,
      token: uuidv4()
    },
    reducers: {
      ...sliceBase.reducers,
      fetchTable: state => {
        state.loading = true;
      },
      setTableData: (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      },
      updateFilter: (state, action) => {
        state.filter = action.payload;
      },
      setTableLoading: (state, action) => {
        state.loading = action.payload;
      },
      refreshTable: state => {
        state.token = uuidv4();
      },
      updateMeta: (state, action) => {
        const { sort, page, limit } = action.payload;
        state.sort = sort;
        state.page = page;
        state.limit = limit;
        state.toklen = uuidv4();
      }
    }
  };
};

const injectTabSlice = ({ initialTab = 0, sliceBase }) => {
  invariant(sliceBase, 'sliceBase not specified');
  return {
    name: sliceBase.name,
    initialState: {
      ...sliceBase.initialState,
      currentTab: initialTab
    },
    reducers: {
      ...sliceBase.reducers,
      setCurrentTab: (state, action) => {
        state.currentTab = action.payload;
      }
    }
  };
};

const injectDetailSlice = ({ sliceBase }) => {
  invariant(sliceBase, 'sliceBase not specified');
  return {
    name: sliceBase.name,
    initialState: {
      ...sliceBase.initialState,
      detail: null,
      detailId: null,
      detailLoading: null
    },
    reducers: {
      ...sliceBase.reducers,
      setDetail: (state, action) => {
        state.detail = action.payload.data;
        state.detailLoading = false;
      },
      fetchDetail: state => {
        state.detailLoading = true;
      },
      setDetailLoading: (state, action) => {
        state.detailLoading = action.payload;
      }
    }
  };
};

const injectAuxTable = ({ sliceBase, tableName }) => {
  invariant(sliceBase, 'sliceBase not specified');
  invariant(tableName, 'tableName not specified');
  const stateFieldName = `${tableName}State`;
  return {
    name: sliceBase.name,
    initialState: {
      ...sliceBase.initialState,
      [tableName]: null,
      [stateFieldName]: LoadingState.IDLE
    },
    reducers: {
      ...sliceBase.reducers,
      [`fetch${capitalizeFirstLetter(tableName)}`]: state => {
        state[stateFieldName] = LoadingState.LOADING;
      },
      [`set${capitalizeFirstLetter(tableName)}`]: (state, action) => {
        if (!action.payload.data) {
          state[stateFieldName] = LoadingState.ERROR;
        } else {
          state[tableName] = action.payload.data;
          state[stateFieldName] = LoadingState.IDLE;
        }
      }
    }
  };
};

const createTableDetailSlice = ({ name, initialSort, limit = 10 }) => {
  const base = createEmptySliceBase({ name });
  const withTable = injectTableSlice({ initialSort, sliceBase: base, limit });
  const withDetail = injectDetailSlice({ sliceBase: withTable });

  return toReducer(withDetail);
};

export {
  createTableSlice,
  injectTableSlice,
  injectTabSlice,
  createEmptySliceBase,
  toReducer,
  injectDetailSlice,
  injectAuxTable,
  createTableDetailSlice
};
