import { injectDetailSlice, injectTableSlice, injectTabSlice, toReducer } from '../sliceFactory';

const createRequestsReducer = () => {
  const sliceBase = {
    name: 'requests',
    initialState: {},
    reducers: {
      createRequest: (state, action) => {
        state.loading = true;
      },
      finishAction: (state, action) => {
        state.loading = false;
      }
    }
  };
  const withTab = injectTabSlice({ initialTab: 0, sliceBase });
  const withTable = injectTableSlice({ initialSort: '-created_at', sliceBase: withTab });
  const withDetail = injectDetailSlice({ sliceBase: withTable });
  return toReducer(withDetail);
};

export default createRequestsReducer;
