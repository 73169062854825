import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentTab: 0
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    }
  }
});

export default reportsSlice.reducer;
