import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  LOGIN_USER_ERROR,
  FORGOT_PASSWORD,
  RESET_PASSWORD
} from '../contants';

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history }
});
export const loginUserSuccess = token => ({
  type: LOGIN_USER_SUCCESS,
  payload: token
});
export const loginUserError = message => ({
  type: LOGIN_USER_ERROR,
  payload: { message }
});

export const logoutUser = history => ({
  type: LOGOUT_USER,
  payload: { history }
});

export const forgotPassword = (email, onFinish) => ({
  type: FORGOT_PASSWORD,
  payload: { email, onFinish }
});

export const resetPassword = (password, recoveryCode, onFinish) => ({
  type: RESET_PASSWORD,
  payload: { password, recovery_code: recoveryCode, onFinish }
});
