const homePath = '/home';
const requestsPath = '/requests';
const transportPath = '/transport';
const partnersPath = '/partners';
const storagesPath = '/storages';
const listsPath = '/lists';
const loginPath = '/user/login';
const forgotPasswordPath = '/user/forgot-password';
const resetPasswordPath = '/user/reset-password/:requestId';
const registrationPath = '/user/register';
const reportsPath = '/reports';
const officeSwitchPath = '/offices';
const invoicesPath = '/invoices';
const profilePath = '/profile';

export {
  homePath,
  requestsPath,
  transportPath,
  partnersPath,
  storagesPath,
  listsPath,
  loginPath,
  registrationPath,
  reportsPath,
  officeSwitchPath,
  invoicesPath,
  profilePath,
  forgotPasswordPath,
  resetPasswordPath
};
