import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentTab: 0,
  officeId: null,
  officeLoading: false,
  officeDetail: null
};

const storagesSlice = createSlice({
  name: 'storages',
  initialState,
  reducers: {
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
    setOfficeId: (state, action) => {
      state.officeId = action.payload;
    },
    setOffice: (state, action) => {
      state.officeDetail = action.payload.data;
      state.officeLoading = false;
    },
    fetchOfficeDetail: state => {
      state.officeLoading = true;
    }
  }
});

export default storagesSlice.reducer;
