import { all, fork, takeEvery } from 'redux-saga/effects';
import { sendData } from '../helpers/sagaHelpers';

function* watchSubmitPricing() {
  yield takeEvery('pricing/submitPricing', sendData);
}

function* watchProcessPricing() {
  yield takeEvery('pricing/processPricing', sendData);
}

export default function* rootSaga() {
  yield all([fork(watchSubmitPricing), fork(watchProcessPricing)]);
}
