import { all, fork, takeLatest } from 'redux-saga/effects';
import { fetchTable } from '../helpers/sagaHelpers';

function* watchFetchFinishedTransports() {
  yield takeLatest('finishedTransports/fetchTable', fetchTable);
}

export default function* rootSaga() {
  yield all([fork(watchFetchFinishedTransports)]);
}
