import { createEmptySliceBase, toReducer } from '../sliceFactory';

const createDocumentsReducer = () => {
  const base = createEmptySliceBase({ name: 'documents' });
  base.initialState.documents = {};
  base.reducers.setUrl = (state, action) => {
    console.log('setUrl', action.payload.document, action.payload.url);
    state.documents[action.payload.document] = action.payload.url;
  };
  return toReducer(base);
};

export default createDocumentsReducer;
