import { all, fork, takeLatest, takeLeading } from 'redux-saga/effects';
import { fetchDetail, fetchTable, sendData } from '../helpers/sagaHelpers';

function* watchFetchSubjectsTable() {
  yield takeLatest('subjects/fetchTable', fetchTable);
}

function* watchFetchDetail() {
  yield takeLatest('subjects/fetchDetail', fetchDetail);
}

function* watchSubmitSubject() {
  yield takeLeading('subjects/submitSubject', sendData);
}

function* watchFetchSubjectOffices() {
  yield takeLatest('subjects/fetchOffices', fetchTable);
}

function* watchFetchSubjectVehicles() {
  yield takeLatest('subjects/fetchVehicles', fetchTable);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchSubjectsTable),
    fork(watchFetchDetail),
    fork(watchSubmitSubject),
    fork(watchFetchSubjectOffices),
    fork(watchFetchSubjectVehicles)
  ]);
}
