import { createEmptySliceBase, injectTableSlice, injectTabSlice, toReducer } from '../sliceFactory';
import * as uuid from 'uuid';

const createTransportReducer = name => {
  const sliceBase = createEmptySliceBase({ name });
  const withTabs = injectTabSlice({ sliceBase });
  return toReducer(withTabs);
};

const createWaitingTransportsReducer = name => {
  const sliceBase = createEmptySliceBase({ name });
  const slice = injectTableSlice({ sliceBase });
  slice.initialState.selection = {};
  slice.initialState.selectedDestinations = [
    { office_id: null, scheduled_at: null, item_categories: [], key: uuid.v4() }
  ];
  slice.initialState.selectedItemTypes = null;
  slice.initialState.plans = [];
  slice.initialState.carriers = [];
  slice.initialState.plansLoading = false;
  slice.initialState.selectedCarrier = null;
  slice.initialState.submittingOrder = false;
  slice.initialState.amendableOrders = [];
  slice.initialState.amendableOrdersLoading = false;
  slice.initialState.selectedAmendableOrder = null;
  slice.initialState.amount = null;
  slice.initialState.weight = null;
  slice.initialState.carrierFilter = {
    query: '',
    region_id: null
  };
  slice.initialState.orderResult = null;

  slice.reducers.setSelection = (state, action) => {
    state.selection = action.payload;
  };
  slice.reducers.clearSelection = state => {
    state.selection = {};
  };
  slice.reducers.setSelectedDestinations = (state, action) => {
    state.selectedDestinations = action.payload;
  };
  slice.reducers.setSelectedItemTypes = (state, action) => {
    state.selectedItemTypes = action.payload;
  };
  slice.reducers.queryAvailableCarriers = state => {
    state.plansLoading = true;
  };
  slice.reducers.setPlans = (state, action) => {
    state.plans = action.payload.plans;
    state.carriers = action.payload.carriers;
    state.plansLoading = false;
    state.amount = action.payload.amount;
    state.weight = action.payload.weight;
  };
  slice.reducers.setSelectedCarrier = (state, action) => {
    state.selectedCarrier = action.payload;
  };
  slice.reducers.setSubmittingOrder = (state, action) => {
    state.submittingOrder = action.payload;
  };
  slice.reducers.reset = state => {
    state.token = uuid.v4();
    state.selection = {};
    state.selectedCarrier = null;
  };
  slice.reducers.clearAvailableTransportation = state => {
    state.plans = [];
    state.amendableOrders = [];
  };
  slice.reducers.queryAvailableOrders = state => {
    state.amendableOrdersLoading = true;
  };
  slice.reducers.setAvailableOrders = (state, action) => {
    state.amendableOrders = action.payload.data;
    state.amendableOrdersLoading = false;
  };
  slice.reducers.selectAmendableOrder = (state, action) => {
    state.selectedAmendableOrder = action.payload;
  };
  slice.reducers.updateCarrierFilter = (state, action) => {
    state.carrierFilter = { ...action.payload };
  };
  slice.reducers.resetSelectedDestionationsAndCarriers = (state, action) => {
    state.selectedDestinations = [
      {
        office_id: null,
        scheduled_at: null,
        item_categories: [],
        key: uuid.v4()
      }
    ];
    state.carriers = [];
    state.selection = {};
  };
  slice.reducers.setOrderResult = (state, action) => {
    state.orderResult = action.payload;
  };

  return toReducer(slice);
};

export { createWaitingTransportsReducer, createTransportReducer };
