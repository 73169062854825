import { all, fork, takeLatest, takeLeading } from 'redux-saga/effects';
import { fetchDetail, sendData } from '../helpers/sagaHelpers';

function* watchFetchOfficeDetail() {
  yield takeLatest('storages/fetchOfficeDetail', fetchDetail);
}

function* watchCreateRequest() {
  yield takeLeading('storages/createRequest', sendData);
}

export default function* rootSaga() {
  yield all([fork(watchFetchOfficeDetail), fork(watchCreateRequest)]);
}
