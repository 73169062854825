import { all, fork, takeLatest } from 'redux-saga/effects';
import { fetchTable } from '../helpers/sagaHelpers';

function* watchFetchTable() {
  yield takeLatest('slnoReports/fetchTable', fetchTable);
}

export default function* rootSaga() {
  yield all([fork(watchFetchTable)]);
}
