import { all, fork, takeLatest, takeLeading } from 'redux-saga/effects';
import { sendData, fetchTable, fetchDetail, deleteObject } from '../helpers/sagaHelpers';

function* watchCreateRequest() {
  yield takeLeading('requests/sendRequest', sendData);
}

function* watchFetchRequestsTable() {
  yield takeLatest('requests/fetchTable', fetchTable);
}

function* watchFetchDetail() {
  yield takeLatest('requests/fetchDetail', fetchDetail);
}

function* watchDeleteRequest() {
  yield takeLatest('requests/deleteRequest', deleteObject);
}

export default function* rootSaga() {
  yield all([
    fork(watchCreateRequest),
    fork(watchFetchRequestsTable),
    fork(watchFetchDetail),
    fork(watchDeleteRequest)
  ]);
}
