import { all, fork, takeLatest } from 'redux-saga/effects';
import { fetchDetail, fetchTable, sendData } from '../helpers/sagaHelpers';

function* watchFetchLimitsEnvidomList() {
  yield takeLatest('limitsEnvidom/fetchTable', fetchTable);
}

function* watchFetchLimitsGovernmentalList() {
  yield takeLatest('limitsGovernmental/fetchTable', fetchTable);
}

function* watchFetchOfficeLimitList() {
  yield takeLatest('officeLimits/fetchTable', fetchTable);
}

function* watchFetchLimitDetail() {
  yield takeLatest('limits/fetchDetail', fetchDetail);
}

function* watchSubmitLimitDetail() {
  yield takeLatest('limits/submitDetail', sendData);
}

function* watchFetchOfficeLimitDetail() {
  yield takeLatest('officeLimits/fetchDetail', fetchDetail);
}

function* watchSubmitOfficeLimitDetail() {
  yield takeLatest('officeLimits/submitDetail', sendData);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchLimitsEnvidomList),
    fork(watchFetchLimitDetail),
    fork(watchSubmitLimitDetail),
    fork(watchFetchLimitsGovernmentalList),
    fork(watchFetchOfficeLimitDetail),
    fork(watchSubmitOfficeLimitDetail),
    fork(watchFetchOfficeLimitList)
  ]);
}
