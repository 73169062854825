import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentTab: 0
};

const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    }
  }
});

export default partnersSlice.reducer;
