import {
  createEmptySliceBase,
  injectDetailSlice,
  injectTableSlice,
  injectTabSlice,
  toReducer
} from '../sliceFactory';

const createInvoicesReducer = ({ name }) => {
  const base = createEmptySliceBase({ name });
  const withTabs = injectTabSlice({ sliceBase: base });
  const withTable = injectTableSlice({ sliceBase: withTabs });
  const withDetail = injectDetailSlice({ sliceBase: withTable });

  return toReducer(withDetail);
};

export default createInvoicesReducer;
