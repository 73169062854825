import { all, fork, takeLatest } from 'redux-saga/effects';
import { fetchDetail, sendData } from '../helpers/sagaHelpers';

function* watchFetchOfficePriceList() {
  yield takeLatest('officePriceLists/fetchDetail', fetchDetail);
}

function* watchSubmitOfficePriceList() {
  yield takeLatest('officePriceLists/submitDetail', sendData);
}

export default function* rootSaga() {
  yield all([fork(watchFetchOfficePriceList), fork(watchSubmitOfficePriceList)]);
}
