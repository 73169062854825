import { getCurrentLanguage, setCurrentLanguage } from '../../helpers/Utils';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  locale: getCurrentLanguage(),
  messages: []
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setLocale: (state, action) => {
      const locale = action.payload;
      setCurrentLanguage(locale);
      state.locale = locale;
    }
  }
});

export default settingsSlice.reducer;
