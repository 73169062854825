import { all, fork, takeLatest } from 'redux-saga/effects';
import { fetchDetail, fetchTable, sendData } from '../helpers/sagaHelpers';

function* watchFetchVehiclePriceLists() {
  yield takeLatest('vehiclePriceLists/fetchTable', fetchTable);
}

function* watchFetchDestinations() {
  yield takeLatest('vehiclePriceLists/fetchDestinations', fetchTable);
}

function* watchSubmitVehiclePriceListDetail() {
  yield takeLatest('vehiclePriceLists/fetchDetail', fetchDetail);
}

function* watchFetchVehiclePriceListDetail() {
  yield takeLatest('vehiclePriceLists/submitDetail', sendData);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchVehiclePriceLists),
    fork(watchFetchDestinations),
    fork(watchSubmitVehiclePriceListDetail),
    fork(watchFetchVehiclePriceListDetail)
  ]);
}
